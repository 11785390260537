import React from "react";
import { Nav, Tab,Button,Image } from "react-bootstrap";
import parse from 'html-react-parser';

const AllServicesTabs2 = (company) => {
  
  let count=0;

  let specialites = company.company.categories.map((item) => {
    return (
      <Nav.Item className="nav-item icon-xs" as="li">
                    <Nav.Button eventKey={"tab-"+count++}>
                      {item.Name.toString().trim()} ({item.items.length})
                    </Nav.Button>
      </Nav.Item>
    );
  });
  let count1=0;
  let tabs = company.company.categories.map((item) => {
      
      return(
        <React.Fragment>
        {
            item.items.map((i)=>{                            
              
              if(company.filter!=null)
                if(item.Name.toString().trim()!=company.filter)
                  return (<span></span>)
              
              
              return(                  
                      <div className="col-md-6 col-lg-4">
                      <div className="doctor-2">
                          {/* Doctor Photo */}
                          <div className="hover-overlay">                                              
                          { i.image!=null &&
                              <img
                                    className="img-fluid"
                                    src={i.image}
                                    alt="doctor-foto"
                                />
                          }
                          { i.image==null &&
                              <img
                                    className="img-fluid"
                                    src="images/medic_jd.jpg"
                                    alt="doctor-foto"
                                />
                          }
                          </div>                        
                          {/* Doctor Meta */}
                          <div className="doctor-meta">
                              <h5 className="h5-xs">
                                  {i.Name}
                              </h5>
                              <span>{item.Name.toString().trim()}</span>
                              {/* Button */}
                                  <Button href={window.lotus+"/DoctorDetail/"+i.id}
                                    variant="link"
                                  >
                                      Mai multe detalii
                                  </Button>
                          </div>
                      </div>
                  </div>
              )                        
            })
          }
           
           </React.Fragment>
      
      )
      count++;  
  });

  
  let e="Echipa noastră";
    if(company.filter!=null)
      e=company.filter;
  
  return (
    <section
            id="doctors-3"
            className="bg-lightgrey wide-60 doctors-section division"
        >
            <div className="container">
                {/* SECTION TITLE */}
                <div className="row">
                    <div className="col-lg-10 offset-lg-1 section-title">
                        {/* Title 	*/}
                        <h3 className="h3-md steelblue-color-color">
                            {e}  
                        </h3>
                        {/* Text */}
                    </div>
                </div>
                {/* END SECTION TITLE */}
                <div className="row">
                   {tabs} 
                </div>
                {/* End row */}
            </div>
            {/* End container */}
        </section>
  );
};

export default AllServicesTabs2;
