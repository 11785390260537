import React from 'react';
import { useState } from "react";
import Header from "@cloudscape-design/components/header";
import Container from "@cloudscape-design/components/container";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import TopNavigation from "./TopNavigation"
import AppLayout from "@cloudscape-design/components/app-layout";
import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";
import TextContent from "@cloudscape-design/components/text-content";
import Grid from "@cloudscape-design/components/grid";
import ColumnLayout from "@cloudscape-design/components/column-layout";
import SideNavigation from './Sidenavigation';
import Gallery from "react-photo-gallery";

export const photos = [
    {
      src: "https://lotusdownload.s3.eu-west-1.amazonaws.com/Images/techxgsm/1.jpeg",
      width: 1,
      height: 1
    },
    {
        src: "https://lotusdownload.s3.eu-west-1.amazonaws.com/Images/techxgsm/2.jpeg",
        width: 1,
        height: 1
      },
      {
        src: "https://lotusdownload.s3.eu-west-1.amazonaws.com/Images/techxgsm/3.jpeg",
        width: 1,
        height: 1
      }
  ];

export default function Code(props) {
    const [value, setValue] = useState("");
    const [menu, setMenu] = useState(false);

    const [activeHref, setActiveHref] = React.useState(
        "#/page1"
    );


    let content = {
        "ro": <SpaceBetween size="m">
            <Container>
                <TextContent>
                    <h1>Reparatii</h1>
                    <hr></hr>
                    <p>Indiferent daca ai nevoie de o baterie pentru dispozitivul tau, sau telefonul necesita schimbarea sticlei, a display-ului, a mufei de alimentare  sau orice alta problema care face ca acesta sa nu fie in conditii perfecte de functionare, te asteptam la atelier pentru a îl face ca nou. </p>
                    <br></br>
                    <center>
                    <div>
                    <Gallery photos={photos} />;                        
                        </div>
                    </center>
                    <br></br>
                </TextContent>
            </Container>
            <Container>
                <TextContent>
                    <h1>Reparaţii software</h1>
                    <hr></hr>
                    <p>Orice se afișează pe ecranul LCD al aparatului este software-ul, cunoscut sub numele de sistem de operare (OS). Sistemul de operare al telefonului mobil se poate corupe cu ușurință şi atunci ai nevoie de un service specializat care să se ocupe de resetarea şi repunerea lui în funcţiune. Te aşteptăm la atelier. </p>
                </TextContent>
            </Container>
            <Container>
                <TextContent>
                    <h1>Înlocuire display</h1>
                    <hr></hr>
                    <p>Inlocuirea display-ului deteriorat consta in demontarea telefonului cu ajutorul echipamentelor de service specifice urmat de curatarea interna a acestuia. Dupa efectuarea acestui proces, display-ul nou va fi montat, respectandu-se atat conditiile de functionare, cat si aspectele estetice. </p>
                </TextContent>
            </Container>
            <Container>
                <TextContent>
                    <h1>Reparatii hardware</h1>
                    <hr></hr>
                    <p>Telefonul mobil este un mini-calculator cu componente hardware , care sunt interdependente și oricare dintre ele poate fi cauza unei defecțiuni.Dacă telefonul nu mai are sunet ,nu mai afișează, ia comenzile greșit, nu mai are semnal sau are o alta defectiune, e momentul sa vi la noi. Ne găseşti aici. Intervenim pentru orice problemă, dupa constatare iti comunicam pretul reparatiei si apoi il facem ca nou. Efectuam reparații hardware pe placa de baza, schimbare componente BGA, conectori, baterie, conectori USB incărcare, reparăm probleme de semnal, incărcare, wifi, senzor proximitate si lumina </p>
                </TextContent>
            </Container>
            <Container>
                <TextContent>
                    <h1>Decodare telefon</h1>
                    <hr></hr>
                    <p>Ai un telefon care functioneaza doar intr-o anumita retea si vrei sa-l folosesti cu alta cartele SIM? Noi te putem ajuta. Vino la atelier pentru a vedea exact ce tip de decodare e potrivita pentru telefonul tau. Ne poţi contacta şi telefonic iar noi îţi spunem un preţ estimativ şi cât ar dura decodarea. </p>
                </TextContent>
            </Container>
            <Container>
                <TextContent>
                    <h1>Folii protecţie</h1>
                    <hr></hr>
                    <p>Paza bună, scapă ecranul spart :). În cadrul service-ului nostru avem un Plotter Devia care ne oferă avantajul de a produce instant folie de protectie, pentru orice dispozitiv smartphone/watch din piata. Folia Devia:</p>

                    <p>➢Este aproape invizibila fiind de doua ori mai subtire decat cea mai fina sticla.</p>

                    <p>➢ Mareste semnificativ rezistenta la impact sau zgariere a ecranului comparativ cu sticla.</p>

                    <p>➢ Spre deosebire de sticla, nu se va sparge. </p>

                    <p>Întreg montajul durează aproximativ 5 minute iar tu te bucuri de un dispozitiv protejat mult timp. </p>
                </TextContent>
            </Container>
        </SpaceBetween>
    }


    return (
        <div>
            <TopNavigation language={props.language} setLanguage={props.setLanguage}
            />
            <AppLayout
                breadcrumbs={<BreadcrumbGroup
                    items={[
                        { text: "TechXGSM", href: window.lotus },
                        {
                            text: "Acasa",
                            href: "/"
                        }
                    ]}
                    ariaLabel="Breadcrumbs"
                />}
                contentHeader={
                    <div>
                        <Grid
                            gridDefinition={[{ colspan: 8 }, { colspan: 6 }]}
                        >
                            <div><Header
                                variant="h1"
                                description={"Telefonul tău are nevoie de o reparație? Suntem aici pentru tine cu servicii profesionale şi de calitate."}
                                actions={
                                    <SpaceBetween direction="horizontal" size="xs">
                                        <Button href={window.lotus + "/services"}>Servicii</Button>
                                        <Button href={window.lotus + "/contact"}>Contact</Button>
                                    </SpaceBetween>
                                }
                            >
                                TechXGSM
                            </Header>
                            </div>
                            <div></div>
                        </Grid>
                    </div>}
                navigation={<SideNavigation language={props.language} setLanguage={props.setLanguage}></SideNavigation>}
                navigationOpen={menu}
                onNavigationChange={(val) => { setMenu(val.detail.open); }}
                toolsHide="false"
                contentType="form"
                content={content[props.language]
                }>

            </AppLayout>
        </div>
    );
}