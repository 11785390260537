import React from "react";

const Orthodontics = (company) => {
  return (
    <div id="single-blog-page" className="wide-100 blog-page-section division">
      <div className="container">
        <div className="row">
          <div className="post-inner-img">
            <h4 className="h4-lg steelblue-color">
            Ortodonţia
            </h4>
          </div>

          <p>
          Ortodonţia constă în îndreptarea şi corectarea poziţiei dinţilor cu ajutorul unui aparat dentar ortodontic. Aceste afecţiuni sunt des întâlnite la copii şi adolescenţi. Părinţii trebuie să fie foarte atenţi cu dinţii copiilor lor, să-i îndrume şi să-i înveţe, încă de mici, să îi îngrijească, pentru a nu apela mai târziu la diverse proceduri stomatologice nedorite.

Înghesuirile şi ocluziile dentare incorecte afectează în timp sănătatea dinţilor, a gingiilor şi a articulaţiei temporo-mandibulare. O masticaţie deficitară poate avea urmări asupra integrităţii tubului digestiv.

          <p>


Progresul tehnicii în stomatologie permite astăzi corectarea spectaculoasă, fără durere, a anomaliilor dento-maxilare. Aparatele dentare sunt aproape invizibile, realizate din materiale de culoarea dinţilor.

În timpul copilăriei şi adolescenţei, oasele maxilare cresc şi se maturizează, de aceea este mai uşor de modificat poziţia dinţilor în această etapă.

În cazul adulţilor, tratamentul ortodontic sau terapeutic poate să dureze mai mult, pentru că oasele maxilare şi-au întrerupt creşterea. Tratamentul dentar este recomandat deoarece, pe termen lung, afecţiunile netratate pot duce la apariţia unor defecte de vorbire, predispoziţie pentru formarea tartrului, implicit a cariilor.
          </p>

           <p>
Ortodonţia nu are limită de vârstă foarte mare. Putem trata copii de la vârste de 5 ani, dar şi adulţi de 40 ani. Cu cât mai devreme tratăm problema, cu atât avem la dispoziţie mai multe opţiuni. În concluzie, este mult mai sănătos sa tratăm malpoziţiile dentare sau dinţii nealiniaţi ortodontic decât non-ortodontic. Ortodontic (prin ortodonţie) corectăm anomalia dentară, păstrăm sănătatea structurilor dentare şi asigurăm stabilitatea în timp a tratamentului, în timp ce non-ortodontic doar o mascăm.

Tratamentul ortodontic este modalitatea clinică prin care se pot corecta dizarmoniile dento-maxilare. Tehnica stomatologică s-a îmbunătăţit simţitor în ultimii ani şi oameni au început să înţeleagă necesitatea aparatului dentar ortodontic şi avantajul unui zâmbet frumos.

Aparatul dentar ortodontic nu este ceva "la modă", este ceva necesar.

Îndreptarea poziţiei dinţilor este un lucru foarte important care nu trebuie tratat ca un moft, ci ca o necesitate.
            </p>
          </p>   
          
        </div>
        {/* End row */}
      </div>
      {/* End container */}
    </div>
  );
};

export default Orthodontics;
