import React from "react";

const Pediatricdentistry = (company) => {
  return (
    <div id="single-blog-page" className="wide-100 blog-page-section division">
      <div className="container">
        <div className="row">
          <div className="post-inner-img">
            <h4 className="h4-lg steelblue-color">
              Stomatologie Pediatrica
            
            </h4>
          </div>

          <p>
          Întâlnirea cu medicul dentist face parte din etapele importante pe care copilul le are de parcurs la început de drum şi care îşi pun amprenta în creşterea şi dezvoltarea sa ca viitor adult. Din acest motiv, este necesar ca primul contact cu medicul dentist să aibă loc la o vârsta cât mai fragedă, iar familializarea cu cabinetul stomatologic să se realizeze treptat, înainte să apară dureri sau necesitatea unor manopere stomatologice laborioase. 
          </p>   
          <p>
          Copiii prezintă necesităţi şi probleme dentare diferite comparativ cu cele ale adulţilor, fiind nevoie să fie trataţi de medici specializaţi în stomatologia pediatrică, într-o ambianţă caldă, care-i va face să se simtă în largul lor. 
          </p>
          <p>
          Atenţia noastră este îndreptata în special către prevenţia leziunilor carioase prin igiena alimentaţiei, instructaj de periaj, fluorizări şi sigilări, profilaxia anomaliilor dento-maxilare prin depistarea şi decondiţionarea obiceiurilor vicioase dar şi tratament de calitate al afecţiunilor existente într-o atmosferă placuta şi prietenoasă, confortabilă pentru micuţul pacient,  care va dori să revină.
          </p>
        </div>
        {/* End row */}
      </div>
      {/* End container */}
    </div>
  );
};

export default Pediatricdentistry;
