import React from 'react';
import { useState } from "react";
import Header from "@cloudscape-design/components/header";
import Container from "@cloudscape-design/components/container";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Input from "@cloudscape-design/components/input";
import Button from "@cloudscape-design/components/button";
import TopNavigation from "./TopNavigation"
import AppLayout from "@cloudscape-design/components/app-layout";
import Badge from "@cloudscape-design/components/badge";
import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";
import TextContent from "@cloudscape-design/components/text-content";
import Grid from "@cloudscape-design/components/grid";
import ColumnLayout from "@cloudscape-design/components/column-layout";
import SideNavigation from './Sidenavigation';
import TagManager from 'react-gtm-module'
import Link from "@cloudscape-design/components/link";
import Icon from "@cloudscape-design/components/icon";
import FormField from "@cloudscape-design/components/form-field";
import Form from "@cloudscape-design/components/form";
import Textarea from "@cloudscape-design/components/textarea";
export default function Contact(props) {
    const [value, setValue] = useState("");
    const [menu, setMenu] = useState(false);
    const [inputName, setInputName] = React.useState("");
    const [inputEmail, setInputEmail] = React.useState("");
    const [inputMessage, setInputMessage] = React.useState("");
    const version="1.12";
    const [activeHref, setActiveHref] = React.useState(
        "#/page1"
    );

    const onSubmit = e => {

        if (inputEmail != "" && inputMessage != "" && inputName != "") {
            let formValues = {
                "Name": inputName,
                "Email": inputEmail,
                "Message": inputMessage
            };

            let data = { "text": JSON.stringify(formValues) };

            fetch('https://chat.googleapis.com/v1/spaces/AAAA130Isj4/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=bVG0KuQwwNJ7T0V_ijKwznCYeq3-ZQynV_eFV8lh3D0%3D', {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            setInputEmail("");
            setInputMessage("");
            setInputName("");
        }
        else
        {
            alert(" Complete all fields!")
        }

    }


    let content = {
        "ro": <SpaceBetween size="m">
            <Container>
                <TextContent>
                    <h1>Contact</h1>
                    <hr></hr>
                    <p>Email: office@lotuscode.eu</p>
                    <hr></hr>                   
                    <p>Alba Iulia, Romania</p>
                    <hr></hr>                   
                </TextContent>
                <Form
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={onSubmit} variant="primary" >Send mesaj</Button>
                        </SpaceBetween>
                    }
                    header={<Header variant="h1">Formular de contact</Header>}
                >
                    <br></br>
                    <br></br>
                    <br></br>
                    <FormField
                        label="Name"
                    >
                        <Input
                            value={inputName}
                            onChange={event =>
                                setInputName(event.detail.value)
                            }
                        />
                    </FormField>
                    <FormField
                        label="Email"
                    >
                        <Input
                            value={inputEmail}
                            inputMode="email"
                            onChange={event =>
                                setInputEmail(event.detail.value)
                            }
                        />
                    </FormField>
                    <FormField
                        label="Mesaj"
                    >
                        <Textarea
                            value={inputMessage}
                            onChange={event =>
                                setInputMessage(event.detail.value)
                            }
                        />
                    </FormField>
                    <p>© {new Date().getFullYear()} <span>Lotus Code</span>. All Rights Reserved - V:{version}</p>
                </Form>
            </Container>
        </SpaceBetween>,
        "en": <SpaceBetween size="m">
            <Container>
                <TextContent>
                    <h1>Contact</h1>
                    <hr></hr>
                    <p>Email: office@lotuscode.us</p>
                    <hr></hr>                   
                </TextContent>
                <Form
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={onSubmit} variant="primary" >Send mesaj</Button>
                        </SpaceBetween>
                    }
                    header={<Header variant="h2">Contact Form</Header>}
                >
                     <br></br>
                    <br></br>
                    <br></br>
                    <Container>
                    <Form>
                    <FormField
                        label="Name"
                    >
                        <Input
                            value={inputName}
                            onChange={event =>
                                setInputName(event.detail.value)
                            }
                        />
                    </FormField>
                    <FormField
                        label="Email"
                    >
                        <Input
                            value={inputEmail}
                            inputMode="email"
                            onChange={event =>
                                setInputEmail(event.detail.value)
                            }
                        />
                    </FormField>
                    <FormField
                        label="Message"
                    >
                        <Textarea
                            rows={15}
                            value={inputMessage}
                            onChange={event =>
                                setInputMessage(event.detail.value)
                            }
                        />
                    </FormField>
                    </Form>
                    </Container>
                    <p>© {new Date().getFullYear()} <span>Lotus Code</span>. All Rights Reserved - V:{version}</p>
                </Form>
            </Container>
        </SpaceBetween>
    }


    return (
        <div>
            <TopNavigation language={props.language} setLanguage={props.setLanguage} region={props.region}
            />
            <AppLayout
                breadcrumbs={<BreadcrumbGroup
                    items={[
                        { text: "Lotus Code", href: window.lotus },
                        {
                            text: "Home",
                            href: "/"
                        }
                    ]}
                    ariaLabel="Breadcrumbs"
                />}
                contentHeader={
                    <div>
                        <Grid
                            gridDefinition={[{ colspan: 8 }, { colspan: 6 }]}
                        >
                            <div><Header
                                variant="h1"
                                description={props.setLanguage == "ro" ? "Partenerul tau pentru solutii moderne in cloud" : "Your partner for modern cloud solutions"}
                                actions={
                                    <SpaceBetween direction="horizontal" size="xs">
                                        <Button href={window.lotus + "/lotusmed"}>Lotus Med</Button>
                                        <Button href={window.lotus + "/lotuslab"}>
                                            Lotus Lab
                                        </Button>
                                        <Button href={window.lotus + "/lotusrx24"}>
                                            Lotus RX24
                                        </Button>
                                    </SpaceBetween>
                                }
                            >
                                Lotus Code
                            </Header>
                            </div>
                            <div></div>
                        </Grid>
                    </div>}
                navigation={<SideNavigation language={props.language} setLanguage={props.setLanguage}></SideNavigation>}
                navigationOpen={menu}
                onNavigationChange={(val) => { setMenu(val.detail.open); }}
                toolsHide="false"
                contentType="form"
                content={content[props.language]
                }>

            </AppLayout>
        </div>
    );
}