import React from 'react';
import { useState } from "react";
import Header from "@cloudscape-design/components/header";
import Container from "@cloudscape-design/components/container";
import SpaceBetween from "@cloudscape-design/components/space-between";
import AppLayout from "@cloudscape-design/components/app-layout";
import SideNavigation from "./Sidenavigation";
import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";
import TextContent from "@cloudscape-design/components/text-content";
import Grid from "@cloudscape-design/components/grid";
import ColumnLayout from "@cloudscape-design/components/column-layout";
import Tabs from "@cloudscape-design/components/tabs";
import TopNavigation from "./TopNavigation"
import Button from "@cloudscape-design/components/button";


export default function Med(props) {
    const [value, setValue] = useState("");
    const [activeHref, setActiveHref] = React.useState(
        "#/page1"
    );

    let content_title={
        "ro":"Platforma de gestiune a activitatii medicale, multicanal construita in cloud.",
        "en":" Electronic Health Record Management Platform, multicanal built in cloud."
    }

    
    let content= {
        "ro": <SpaceBetween size = "m">
        <Container>
            <TextContent>
                <h1> Gestiunea eficientă a programarilor</h1>
                <hr></hr>
                <h4>Gestionați clienții, programarea și fisa pacient dintr-o aplicație sigură.</h4>
                <hr></hr>
                <h4>Toate comunicatiile intr-un singur loc.</h4>
                <hr></hr>
                <h4>Comunicarea eficienta este cheia succesului, iar comunicarea pe mai multe canale iti asigura o ascensiune mai rapida.</h4>
                <hr></hr>
                <h4>Optimizeaza timpul de lucru prin reducerea timpului necesar realizarii sarcinilor curente. </h4>
                <hr></hr>
                <ColumnLayout columns={1}>
                    <div><img
                        className="img-fluid"
                        src="images/med/ro/Appointments.png"
                        alt="info-image"
                        width="100%"
                    /></div>
                </ColumnLayout>
            </TextContent >
        </Container >
        <Container>
            <TextContent>
                <h1>Fisa pacient</h1>
                <hr></hr>
                <h4>Gestioneaza consulatiile pacientilor</h4>
                <hr></hr>
                <h4>Incarca si salveaza fisiere si imagini</h4>
                <hr></hr>
                <h4>Gestioneaza rapid programarile</h4>
                <hr></hr>
                <h4>Preconfigureaza sabloane pentru servicii pe care le poti importa rapid in fisa pacient, astfel poti sa completezi mai rapid documentele</h4>
                <hr></hr>
                <Tabs
                    tabs={[
                        {
                            label: "Fisa pacient",
                            id: "first",
                            content: <ColumnLayout columns={1}>
                                <div><img
                                    className="img-fluid"
                                    src="images/med/ro/PatientFile.png"
                                    alt="info-image"
                                    width="100%"
                                /></div>
                            </ColumnLayout>
                        },
                        {
                            label: " Documente",
                            id: "second",
                            content: <ColumnLayout columns={1}>
                                <div><img
                                    className="img-fluid"
                                    src="images/med/ro/PatientFileDocuments.png"
                                    alt="info-image"
                                    width="100%"
                                /></div>
                            </ColumnLayout>
                        },
                    ]}
                />

            </TextContent>
        </Container>
        {/*
        <Container>
            <TextContent>
                <h1> WebSite - Creat automat pe baza datelor introduse in platforma</h1>
                <hr></hr>
                <p>De obicei companiile își creaza un site separat de aplicatia de gestiune medicala.</p>
                <p>Din acest motiv multe informații nu vor fi actualizate la timp, sau anumite evenimente din website nu vor ajunge în timp util la persoanele decidente</p>
                <p>Informațiile din Politică de Confidentialitate, Formular de evaluare, Contact sunt transmise automat in platforma si in acelasi timp vei primi notificari</p>
                <br></br>
                <ColumnLayout columns={1}>
                    <div><img
                        className="img-fluid"
                        src="images/med/ro/Specia.png"
                        alt="info-image"
                        width="100%"
                    /></div>
                </ColumnLayout>
            </TextContent>
        </Container>
        */}
        <Container>
            <TextContent>
                <h1> Programari online</h1>
                <hr></hr>
                <h4>Pacienți pot face programari automat în platforma fara interventie umana, pe baza programului medicului, investigației și a unui algoritm propriu platformei.</h4>
                <h4>La orice ora din zi, sau noapte pacientul poate sa se programeze fara interventie umana</h4>
                <h4>O mare parte dintre pacienți nu mai doresc interacțiune umană atunci cand vorbim de programari</h4>
                <br></br>              
            </TextContent>
        </Container>
        <Container>
            <TextContent>
                <h1>Voce</h1>
                <hr></hr>
                <h4> Platforma poate primi si efectua apeluri telefonice.</h4>
                <br></br>
                <h4> Plaforma permite activarea unui robot telefonic care poate prelua mesaje.</h4>
                <br></br>
                <h4> Plaforma permite confirmarea programarilor prin apelarea pacientilor</h4>
                <br></br>
                <h4> Plaforma te notifica atunci cind se pierde un apel de la un pacient</h4>
                <br></br>
                <h4> Ai toate contactele contralizat si toate apelurile</h4>
                <h1>Chat</h1>
                <hr></hr>
                <h4> Platforma poate prelua mesaje chat de pe site si le poate direactiona spre frontdesk</h4>
                <br></br>
                <h1>SMS</h1>
                <hr></hr>
                <h4>Platformă poate trimite mesaje SMS pentru a reaminti pacienților de programari</h4>
                <br></br>
                <h4>Platformă poate genera campanii SMS targetate pe un anumit segment de pacienti</h4>
                <br></br>
                <h4>Platforma permite trimiterea manuala sau automata a mesajelor personalizate catre pacientii dumneavoastra</h4>
                <br></br>
                <h4>Pacientii pot răspunde la acest mesaj, iar acest mesaj va fi transferat care frontdesk, în cazul care nu au fost stabilite acțiuni prestabilite. </h4>
                <br></br>
                <h1>Email</h1>
                <hr></hr>
                <h4> Platforma poate trimite emailuri pentru confirmare programare, emailuri cu radiografii sau alte informații necesare pacientului.</h4>
            </TextContent>
        </Container>
        <Container>
            <TextContent>
                <h1>Aplicație mobilă Android și Apple</h1>
                <hr></hr>
                <h4>Acces prin aplicație la agenda programari</h4>
                <hr></hr>
                <h4>Personalul medical are permanent o imagine de asamblu asupra calendarului de programari pe care il poate accesa de pe telefonul mobil.</h4>
                <hr></hr>
                <ColumnLayout columns={1}>
                    <div><img
                        className="img-fluid"
                        src="images/med/ro/LotusApp.png"
                        alt="info-image"
                        width="100%"
                    /></div>
                </ColumnLayout>
            </TextContent>
        </Container>
        <Container>
            <TextContent>
                <h1> Rapoarte inteligente</h1>
                <hr></hr>
                <h4>Eficientizarea resurselor folosite in practica medicala si in managementul de zi cu zi sunt fundamentale pentru succesul afacerii tale.</h4>
                <hr></hr>
                <h4>Prin interfata platformei, ai acces in orice moment la situatia financiara si poti lua decizii pe baza cifrelor din rapoarte.</h4>
                <hr></hr>
                <h4>Poti studia in timp real statistici grafice, personalizate, direct pe telefon, tableta sau desktop.</h4>
                <hr></hr>
                <h4>In acest fel aveti în orice moment o viziune de ansamblu si foarte clară asupra afacerii.</h4>
                <hr></hr>                
                <ColumnLayout columns={1}>
                    <div><img
                        className="img-fluid"
                        src="images/med/ro/Reports.png"
                        alt="info-image"
                        width="100%"
                    /></div>
                </ColumnLayout>
            </TextContent>
        </Container>
        <Container>
            <TextContent>
                <h1> Cloud AWS</h1>
                <hr></hr>
                <p>Datele D-voastra sunt în siguranța în centre de date care valorează miliarde de dolari. Costa sume importante pentru ca sunt construite special să asigure o disponibilitate de peste 99.99% a tuturor sistemelor. Datele sunt securizate si criptate. Pentru aceste date se face backup zilnic automat. Bazele de date sunt special concepute sa functioneze cu disponibilitate de 99.99% avand tot timpul 6 copii ale datelor în 3 zonele ale regiunii. Arhitectura bazată pe microservicii asigura că întregul sistem sa raspunda cerintelor, alocând resurse suplimentare atunci cand este nevoie. Platforma monitorizează atent ca toate servicii sa funcționeze corect, avand capacitati de a se vindeca automat sistemul si a reporni anumite servicii în caz de avarie.</p>
            </TextContent>
        </Container>

    </SpaceBetween >,
    "en": <SpaceBetween size="m">
            <Container>
                <TextContent>
                    <h1> Effective schedule management</h1>
                    <hr></hr>
                    <h4>Manage your clients, appointment and patient record from one secure app.</h4>
                    <hr></hr>
                    <h4>All communications in one place.</h4>
                    <hr></hr>
                    <h4>Effective communication is the key to success, and communication on several channels ensures a faster ascent.</h4>
                    <hr></hr>
                    <h4>It optimizes working time by reducing the time needed to perform current tasks. </h4>
                    <hr></hr>
                    <ColumnLayout columns={1}>
                        <div><img
                            className="img-fluid"
                            src="images/med/en/Appointments.png"
                            alt="info-image"
                            width="100%"
                        /></div>
                    </ColumnLayout>
                </TextContent>
            </Container>
            <Container>
                <TextContent>
                    <h1>Patient file</h1>
                    <hr></hr>
                    <h4>Manages patient consultations</h4>
                    <hr></hr>
                    <h4>Upload and save files and images</h4>
                    <hr></hr>
                    <h4>Quickly manage appointments</h4>
                    <hr></hr>
                    <h4>Pre-configure templates for services that you can quickly import into the patient file, so you can complete the documents faster</h4>
                    <hr></hr>
                    <br></br>
                    <Tabs
                        tabs={[
                            {
                                label: "Patient file",
                                id: "first",
                                content: <ColumnLayout columns={1}>
                                    <div><img
                                        className="img-fluid"
                                        src="images/med/en/PatientFile.png"
                                        alt="info-image"
                                        width="100%"
                                    /></div>
                                </ColumnLayout>
                            },
                            {
                                label: "Documents",
                                id: "second",
                                content: <ColumnLayout columns={1}>
                                    <div><img
                                        className="img-fluid"
                                        src="images/med/ro/PatientFileDocuments.png"
                                        alt="info-image"
                                        width="100%"
                                    /></div>
                                </ColumnLayout>
                            },
                        ]}
                    />

                </TextContent>
            </Container>           
            {/*
            <Container>
                <TextContent>
                    <h1>WebSite - Created automatically based on the data entered in the platform</h1>
                    <hr></hr>
                    <p>Companies usually create a website separate from the medical management application.</p>
                    <p>For this reason, many information will not be updated on time, or certain events on the website will not reach decision-makers in a timely manner</p>
                    <p>The information from the Privacy Policy, Evaluation Form, Contact are automatically sent to the platform and at the same time you will receive notifications</p>
                    <br></br>
                    <ColumnLayout columns={1}>
                        <div><img
                            className="img-fluid"
                            src="images/med/ro/Specia.png"
                            alt="info-image"
                            width="100%"
                        /></div>
                    </ColumnLayout>
                </TextContent>
            </Container>
            */}
            <Container>
                <TextContent>
                    <h1> Online appointments</h1>
                    <hr></hr>
                    <h4>Patients can make appointments automatically in the platform without human intervention, based on the doctor's schedule, the investigation and an algorithm specific to the platform.</h4>
                    <br></br>
                    <h4>At any time of the day or night, the patient can make an appointment without human intervention</h4>
                    <br></br>
                    <h4>A large part of patients no longer want human interaction when we talk about appointments</h4>
                    <br></br>                    
                </TextContent>
            </Container>            
            <Container>
                <TextContent>
                    <h1>Voice</h1>
                    <hr></hr>
                    <h4> The platform can receive and make phone calls.</h4>
                    <br></br>
                    <h4> The platform allows the activation of an answering machine that can take messages.</h4>
                    <br></br>
                    <h4> The platform allows confirmation of appointments by calling patients</h4>
                    <br></br>
                    <h4> The platform notifies you when a call from a patient is lost</h4>
                    <br></br>
                    <h4> You have all your contacts checked and all your calls</h4>
                    <br></br>
                    <h1>Chat</h1>
                    <hr></hr>
                    <p> The platform can take chat messages from the website and direct them to the front desk.</p>
                    <br></br>                    
                    <h1>SMS</h1>
                    <hr></hr>
                    <h4>The platform can send SMS messages to remind patients of appointments</h4>
                    <br></br>
                    <h4>The platform can generate SMS campaigns targeted at a certain segment of patients</h4>
                    <br></br>
                    <h4>The platform allows manual or automatic sending of personalized messages to your patients</h4>
                    <br></br>
                    <h4>Patients can reply to this message, and this message will be transferred to the front desk, if no default actions have been set. </h4>
                    <br></br>
                    <h1>Email</h1>
                    <hr></hr>
                    <h4> The platform can send emails for appointment confirmation, emails with x-rays or other information needed by the patient.</h4>
                </TextContent>
            </Container>
            <Container>
                <TextContent>
                    <h1>Android and Apple mobile app</h1>
                    <hr></hr>
                    <p>Access through the application to the agenda</p>
                    <p>The medical staff always has an overview of the appointment calendar that they can access from their mobile phone.</p>
                    <ColumnLayout columns={1}>
                        <div><img
                            className="img-fluid"
                            src="images/med/en/LotusApp.png"
                            alt="info-image"
                            width="100%"
                        /></div>
                    </ColumnLayout>
                </TextContent>
            </Container>
            <Container>
                <TextContent>
                    <h1> Smart reports</h1>
                    <hr></hr>
                    <h4>The efficiency of the resources used in medical practice and in day-to-day management are fundamental for the success of your business.</h4>
                    <hr></hr>
                    <h4>Through the platform interface, you have access to the financial situation at any time and you can make decisions based on the figures in the reports.</h4>
                    <hr></hr>
                    <h4>You can study real-time graphic statistics, personalized, directly on your phone, tablet or desktop.</h4>
                    <hr></hr>
                    <h4>In this way, you have a very clear overview of the business at any time.</h4>
                    <hr></hr>
                    <ColumnLayout columns={1}>
                        <div><img
                            className="img-fluid"
                            src="images/med/en/Reports.png"
                            alt="info-image"
                            width="100%"
                        /></div>
                    </ColumnLayout>
                </TextContent>
            </Container>
            <Container>
                <TextContent>
                    <h1> AWS Cloud</h1>
                    <hr></hr>
                    <p>Your data is safe in multi-billion dollar data centers. They cost significant amounts because they are specially built to ensure over 99.99% availability of all systems. The data is secured and encrypted. These data are automatically backed up daily. The databases are specially designed to operate with 99.99% availability, always having 6 copies of the data in 3 areas of the region. The architecture based on microservices ensures that the entire system responds to the requirements, allocating additional resources when needed. The platform carefully monitors that all services work correctly, having the ability to automatically heal the system and restart certain services in case of failure.</p>
                </TextContent>
            </Container>

        </SpaceBetween>
}

return (
    <div>
        <TopNavigation language={props.language} setLanguage={props.setLanguage} region={props.region} />
        <AppLayout
            breadcrumbs={<BreadcrumbGroup
                items={[
                    { text: "Lotus Code", href: window.lotus },
                    {
                        text: "Lotus Med",
                        href: "/lotusmed"
                    }
                ]}
                ariaLabel="Breadcrumbs"
            />}
            contentHeader={
                <Grid
                    gridDefinition={[{ colspan: 8 }, { colspan: 6 }]}
                >
                    <div><Header
                        variant="h1"
                        description={content_title[props.language]}
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                {(props.language == "ro" && props.region == "eu") &&
                                    <Button variant="primary" href={"https://med.lotuscode.eu/createaccount?region=eu&lang=ro"}>Creaza un cont in platforma</Button>
                                }
                                {(props.language == "en" && props.region == "eu") &&
                                    <Button variant="primary" href={"https://med.lotuscode.eu/createaccount?region=eu&lang=en"}>Create account</Button>
                                }
                                {props.region == "us" &&
                                    <Button variant="primary" href={"https://med.lotuscode.eu/createaccount?region=us&lang=en"}>Create account</Button>
                                }
                            </SpaceBetween>
                        }
                    >
                        Lotus Med
                    </Header>
                    </div>                    
                </Grid>}
            navigation={<SideNavigation language={props.language}  setLanguage={props.setLanguage}></SideNavigation>}
            toolsHide="false"
            content={content[props.language]}>
        </AppLayout>
    </div>
);
}