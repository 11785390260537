import React from 'react';
import { useState } from "react";
import SideNavigation from "@cloudscape-design/components/side-navigation";
import { propTypes } from 'react-bootstrap/esm/Image';


export default function Code(props) {
    const [value, setValue] = useState("");
    const [activeHref, setActiveHref] = React.useState(
        window.location.pathname
    );


    return (
     <SideNavigation
                activeHref={activeHref}
                header={{ href: "/", text: "TechXGSM"}}
                onFollow={event => {                    
                    if (!event.detail.external) {
                        event.preventDefault();
                        setActiveHref(event.detail.href);                                           
                        location.assign(window.lotus + event.detail.href);                 
                    }
                }}
                items={[
                    { type: "link", text: props.language == "ro"?"Acasa":"Home" , href: "/" },
                    { type: "divider" },                   
                    { type: "link", text: props.language == "ro"?"Servicii":"Services" , href: "/services" },
                    { type: "divider" },
                    { type: "link", text: props.language == "ro"?"Contact":"Contact" , href: "/contact" },
                ]}
            />
    );
}