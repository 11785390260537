import React from "react";

const Implant = (company) => {
  return (
    <div id="single-blog-page" className="wide-100 blog-page-section division">
      <div className="container">
        <div className="row">
            <h1 >
              Implant
            </h1>
        </div>
        <div className="row">
         
          <p></p>
          <p></p>
          <p></p>
          <p>
            <h4>
              Ce sunt implanturile dentare?
            </h4>
          </p>
          <p>
          Implantul dentar este o piesă din titan pur (99,95%), având forma unui şurub. El are menirea de a crea o rădăcină artificială în osul maxilar, acolo unde dintele sau dinţii naturali lipsesc.
          Reconstrucţia este formată din două segmente- unul intraosos (implantul) şi unul supraosos numit bont protetic. Deasupra bontului protetic se ancorează o coroană, o punte sau o proteză. Tratamentul este conservator în comparaţie cu o lucrare tradiţională prin punte deoarece implanturile elimină necesitatea sacrificării dinţilor învecinaţi. În cazul în care confecţionăm o punte dentară, aceasta are nevoie pentru ancorare de minimum 2 dinţi stâlpi. Restaurarea danturii pe implanturi aduce beneficii estetice şi funcţionale extraordinare, pacientul nepercepând senzaţia de “artificial”, în scurt timp, acesta uitând că de fapt a pierdut în acel loc un dinte.
          </p>
          <p>
          Avantajele protezării pe implanturi: deoarece înlocuieşte un singur dinte şi nu trei ca în cazul unei punţi, un implant dentar are un cost apropiat unei lucrări tradiţionale metaloceramice.
            </p>
            <p>
            Salvează dinţii – implanturile dentare nu sacrifică integritatea dinţilor învecinaţi cum ar face o punte dentară, deoarece aceşti dinţi nu mai au de sprijinit stâlpii de punte. Dinţii dumneavoastră sănătoşi nu sunt afectaţi sub nici o formă, acesta fiind în beneficiul sănătăţii dumneavoastră orale.
            </p>
            <p>
            Confortabil – implanturile dentare vă permit să vorbiţi şi să mâncaţi confortabil şi cu încredere. Coroanele susţinute de implanturi sunt ancorate fix şi transmit presiunile din timpul masticaţiei fiziologice în os, asemenea unui dinte natural.
              </p>
              <p>
              
              Rata de succes a înlocuirii dinţilor prin implanturi este foarte mare (95%-mandibulă şi 90%-maxilar), dacă pacientul prezintă o stare de sănătate generală şi locală  bună, prezintă o igienă dentară bună, nu este un mare fumător sau consumator de băuturi alcoolice în exces.

                  Nu în ultimul rând, luăm în discuţie calificarea medicului (obligatoriu cu competenţa în acest domeniu, specializări şi experienţă ) cât şi dotarea clinicii în care are loc intervenţia chirurgicală (sala de operaţii dedicată acestui scop, cu toată aparatura necesară intervenţiei şi cu metode totale de investigare pre-, intra-, şi postoperator). Fiind o manoperă chirurgicală pură, condiţiile de sterilizare vor fi foarte riguroase, normele de asepsie şi antisepsie trebuie sa fie respectate cu rigurozitate.

                  Depinzând de condiţiile dvs. specifice şi de tipul de implant  ales, implantologul dvs. vă va crea un plan de tratament.Timpul pentru un tratament poate ajunge de la câteva sãptãmâni până la un an depinzând de complexitatea tratamentului. Este necesar sã colaboraţi cu implantologul dvs. ca într-o echipă, pentru a ajunge la rezultatul dorit.
             </p>
              <p>

              Implanturile dentare pot înlocui un singur dinte, mai multi dinţi , sau toţi dinţii dvs. Calitatea şi cantitatea osului sunt de o importanţa majoră. Dacã osul este iniţial insuficient, atunci se poate practica o procedurã de adăugire numită augmentare osoasă. Aceasta se poate face în funcţie de caz în timpul intervenţiei chirurgicale de inserare a implanturilor sau separat. Medicul implantolog va alege tipul de os necesar augmentării şi dacă e nevoie va utiliza o membrană biologică cu care va acoperi transplantul osos.

                Sunt situaţii care, în cazul în care se doreşte inserarea unuia sau mai multor implanturi în regiunea laterală a maxilarului, aceasta să nu poată fi facută datorită prezenţei unui sinus maxilar coborât şi voluminos. Pentru aceasta, există procedura chirurgicală numită “sinus lifting” (ridicarea membranei sinusului).
              </p>


        </div>
        {/* End row */}
      </div>
      {/* End container */}
    </div>
  );
};

export default Implant;
