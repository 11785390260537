import React from 'react';
//import Footer from './UI/Footer';
//import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router,Switch, Route, Redirect } from "react-router-dom";
//import AppNot from './AppNot';
import ServicesMed from './layout/Service/AllServicesTabs2'
import ServicesList from './layout/Service/ServicesList'
import Evaluation from './layout/Evaluation/Evaluation'

import GDPR from './layout/Gdpr/Gdpr';
import Layouts from './layout/Layouts'

import Contacts from './layout/Contact/Contacts'
import RequestHarvestLab from './layout/LabRequest/RequestHarvestLab'
import RequestStartLab from './layout/LabRequest/RequestStartLab'
import OnlineAccessLab from './layout/LabRequest/OnlineAccessLab'
import ServiceListLab from './layout/LabRequest/ServiceListLab'
import HowTo from './layout/LabRequest/HowTo'

import Hero from "./layout/Hero/Hero";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Sitemap from "./Sitemap";



class AppLab extends React.Component {

  

  render() {
    
    let localhost=window.location.origin.toString();
    window.lotus=localhost;

    let title=this.props.company.CONFC_Name+" "+this.props.company.CONFC_City;
    return (
      <HelmetProvider>
        <Helmet>
        <title>{title}</title>
        <meta name="description" content={this.props.company.description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={localhost} />
        <meta property="og:title" content={title}/>
        <meta property="og:description" content={this.props.company.description}/>
        <link rel="canonical" href={localhost} />           
        </Helmet>
     <div>                   
        <div>
          <Router>
          <Switch>        
            
         
            <Route  path="/sitemap">
            <Sitemap company={this.props.company}></Sitemap>
            </Route>
            
            <Route exact  path="/" render={(match) => (
                 <Layouts  company={this.props.company} config={this.props.config} >               
                  <Hero  company={this.props.company}   />                                         
                  
                  { this.props.config.module.find(x=>x.code=="RequestLab") &&
                    <RequestStartLab company={this.props.company}></RequestStartLab>                                     
                  }

                  
                  
                  { this.props.config.module.find(x=>x.code=="ServicesMed") &&
                    <React.Fragment>
                      <ServicesList company={this.props.company}></ServicesList>   
                      <ServicesMed company={this.props.company}></ServicesMed>                                  
                    </React.Fragment>
                  }

                </Layouts>
            )} />


             
            { this.props.config.module.find(x=>x.code=="RequestLab") &&
                  <Route path="/RequestHarvestLab" exact render={(match) => (
                    <Layouts company={this.props.company} config={this.props.config}  >
                    <RequestHarvestLab company={this.props.company}></RequestHarvestLab>       
                    </Layouts>                          
                )} />
             }

             { this.props.config.module.find(x=>x.code=="RequestLab") &&
                  <Route path="/HowTo" exact render={(match) => (
                    <Layouts company={this.props.company} config={this.props.config}  >
                    <HowTo company={this.props.company}></HowTo>       
                    </Layouts>                          
                )} />
             }


             { this.props.config.module.find(x=>x.code=="RequestLab") &&
                  <Route path="/OnlineAccessLab" exact render={(match) => (
                    <Layouts company={this.props.company} config={this.props.config}  >
                    <OnlineAccessLab company={this.props.company}></OnlineAccessLab>       
                    </Layouts>                          
                )} />
             }

             { this.props.config.module.find(x=>x.code=="RequestLab") &&
                  <Route path="/ServiceListLab" exact render={(match) => (
                    <Layouts company={this.props.company} config={this.props.config} >
                    <ServiceListLab company={this.props.company}></ServiceListLab>       
                    </Layouts>                          
                )} />
             }

         
            <Route path="/Policy" exact render={(match) => (
                <Layouts company={this.props.company} config={this.props.config}  >
                <GDPR  company={this.props.company}/>      
                </Layouts>                          
            )} />

            
            <Route path="/Evaluation" exact render={(match) => (
                <Layouts company={this.props.company} config={this.props.config}  >
                <Evaluation  company={this.props.company}/>      
                </Layouts>                          
            )} />
           

            <Route path="/Contact" exact render={(match) => (
                <Layouts company={this.props.company} config={this.props.config}  >
                <Contacts  company={this.props.company}/>      
                </Layouts>                          
            )} />

          </Switch>
          </Router>
        </div>
      </div>
      </HelmetProvider>
    );
    
  }

}

export default AppLab;
