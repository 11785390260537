var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Button } from 'react-bootstrap';
import * as React from "react";
import parse from 'html-react-parser';
import ReCAPTCHA from "react-google-recaptcha";
import { useState, useCallback } from "react";
import * as request from 'superagent';
import { API_SERVICEENDPOINT } from '../../../constants/endpoints';
var Contacts = function (company) {
    var _a = useState(""), captcha = _a[0], setCaptcha = _a[1];
    var _b = useState(""), inputName = _b[0], setInputName = _b[1];
    var _c = useState(""), inputSubject = _c[0], setInputSubject = _c[1];
    var _d = useState(""), inputPhone = _d[0], setInputPhone = _d[1];
    var _e = useState(""), inputEmail = _e[0], setInputEmail = _e[1];
    var _f = useState(""), inputMessage = _f[0], setInputMessage = _f[1];
    var _g = useState(""), message = _g[0], setMessage = _g[1];
    var _h = useState(false), validated = _h[0], setValidated = _h[1];
    var onContact = useCallback(function (inputName, inputSubject, inputPhone, inputEmail, inputMessage, captcha) { return function () { return __awaiter(void 0, void 0, void 0, function () {
        var error;
        return __generator(this, function (_a) {
            error = "";
            if (inputName == "")
                error += " Nume ";
            if (inputSubject == "")
                error += " Subiect ";
            if (inputPhone == "")
                error += " Telefon ";
            if (inputEmail == "")
                error += " Email ";
            if (captcha == "")
                error += " Captcha ";
            if (inputMessage == "")
                error += " Mesaj ";
            if (error) {
                setValidated(true);
                setMessage(error);
            }
            else {
                setMessage("");
                request
                    .post(API_SERVICEENDPOINT + '/api/online/Contact')
                    .set('Content-Type', 'application/json;charset=UTF-8')
                    .set('Access-Control-Allow-Origin', '*')
                    .set("captcha", captcha)
                    .set("name", inputName)
                    .set("email", inputEmail)
                    .set("phone", inputPhone)
                    .set("subject", inputSubject)
                    .set("message", inputMessage)
                    .end(function (err, res) {
                    if (res != null) {
                        var obj = JSON.parse(res.text);
                        if (obj.Status == "OK") {
                            alert("Mesajul a fost trimis cu success!");
                            location.reload();
                        }
                    }
                });
            }
            return [2 /*return*/];
        });
    }); }; }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("iframe", { src: "https://maps.google.com/maps?q=" + encodeURIComponent(company.company.CONFC_Address) + "&t=&z=13&ie=UTF8&iwloc=&output=embed", height: 450, width: "100%", style: { border: 0 }, allowFullScreen: true, loading: "lazy" }),
        React.createElement("section", { id: "contacts-1", className: "wide-60 contacts-section division" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-lg-10 offset-lg-1 section-title" },
                        React.createElement("h3", { className: "h3-md steelblue-color" }, "Doriti sa ne adresati o intrebare ?"))),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-md-5 col-lg-4" },
                        React.createElement("div", { className: "contact-box mb-40" },
                            React.createElement("h5", { className: "h5-sm steelblue-color" }, "Contact"),
                            React.createElement("p", null, company.company.CONFC_Address),
                            React.createElement("p", null,
                                "Email:",
                                React.createElement("a", { onClick: function (e) {
                                        e.preventDefault();
                                    }, href: "mailto:" + company.company.CONFC_Email }, company.company.CONFC_Email)),
                            React.createElement("p", null,
                                "Telefon: +",
                                company.company.CONFC_Phone)),
                        React.createElement("div", { className: "contact-box mb-40" },
                            React.createElement("h5", { className: "h5-sm steelblue-color" }, "Program"),
                            parse(company.company.CONFC_Schedule))),
                    React.createElement("div", { className: "col-md-7 col-lg-8" },
                        React.createElement("div", { className: "form-holder mb-40" },
                            React.createElement("form", { name: "contactForm", onSubmit: function (e) { return e.preventDefault(); }, className: "row contact-form" },
                                React.createElement("div", { id: "input-name", className: "col-md-12 col-lg-6" },
                                    React.createElement("input", { type: "text", name: "name", className: "form-control name", placeholder: "Nume*", required: true, onChange: function (e) { setInputName(e.target.value); } })),
                                React.createElement("div", { id: "input-email", className: "col-md-12 col-lg-6" },
                                    React.createElement("input", { type: "text", name: "email", className: "form-control email", placeholder: "Email*", required: true, onChange: function (e) { setInputEmail(e.target.value); } })),
                                React.createElement("div", { id: "input-phone", className: "col-md-12 col-lg-6" },
                                    React.createElement("input", { type: "tel", name: "phone", className: "form-control phone", placeholder: "Numar de telefon", required: true, onChange: function (e) { setInputPhone(e.target.value); } })),
                                React.createElement("div", { id: "input-subject", className: "col-lg-12" },
                                    React.createElement("input", { type: "text", name: "subject", className: "form-control subject", placeholder: "Subiect", required: true, onChange: function (e) { setInputSubject(e.target.value); } })),
                                React.createElement("div", { id: "input-message", className: "col-lg-12 input-message" },
                                    React.createElement("textarea", { className: "form-control message", name: "message", rows: 6, placeholder: "Mesaj", required: true, defaultValue: "", onChange: function (e) { setInputMessage(e.target.value); } })),
                                React.createElement(ReCAPTCHA, { hl: "ro", sitekey: "6Ld9MO4ZAAAAALl4VT7Rdy6Mot2q6fLxu42jEcDg", onChange: setCaptcha }),
                                React.createElement("div", { className: "col-lg-12 mt-15 form-btn" },
                                    message,
                                    React.createElement(Button, { type: "submit", className: "btn btn-blue blue-hover submit", onClick: onContact(inputName, inputSubject, inputPhone, inputEmail, inputMessage, captcha) }, "Trimite mesaj")),
                                React.createElement("div", { className: "col-lg-12 contact-form-msg text-center" },
                                    React.createElement("div", { className: "sending-msg" },
                                        React.createElement("span", { className: "loading" })))))))))));
};
export default Contacts;
