import React from 'react';
import { useState } from "react";
import Header from "@cloudscape-design/components/header";
import Container from "@cloudscape-design/components/container";
import SpaceBetween from "@cloudscape-design/components/space-between";
import AppLayout from "@cloudscape-design/components/app-layout";
import SideNavigation from "./Sidenavigation";
import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";
import TextContent from "@cloudscape-design/components/text-content";
import Grid from "@cloudscape-design/components/grid";
import ColumnLayout from "@cloudscape-design/components/column-layout";
import TopNavigation from "./TopNavigation"
import Button from "@cloudscape-design/components/button";

export default function Factory(props) {
    const [value, setValue] = useState("");

    let content = {
        "en":<SpaceBetween size="m">
        <Container>
            <TextContent>
                <h1>Lotus Factory - Custom Software</h1>
                <hr></hr>
                <p>Process management for factories</p>
                <p>Track processes, projects, departments, defects, hours worked inside a factory</p>
                <p>FAULTS</p>
                <p>Hours worked</p>
                <p>Reports</p>
                <p>Costs</p>
                <ColumnLayout columns={1}>
                    <div><img
                        className="img-fluid"
                        src="images/factory/ro/factory.webp"
                        alt="info-image"
                        width="100%"
                    /></div>
                </ColumnLayout>
                <br></br>                
            </TextContent>
        </Container>
    </SpaceBetween>,
    "ro":
    <SpaceBetween size="m">
         <Container>
            <TextContent>
                <h1>Lotus Factory - Software la comanda </h1>
                <hr></hr>
                <p>Management procese pentru fabrici</p>
                <p>Urmărește procesele, proiecte, departamentele ,defecte ,ore lucrate în interiorul unei fabrici</p>
                <p>Defecte</p>
                <p>Ore lucrate</p>
                <p>Rapoarte</p>
                <p>Cheltuieli</p>
                <ColumnLayout columns={1}>
                    <div><img
                        className="img-fluid"
                        src="images/factory/ro/factory.webp"
                        alt="info-image"
                        width="100%"
                    /></div>
                </ColumnLayout>
                <br></br>                
            </TextContent>
        </Container>
    </SpaceBetween>
    }


    return (
        <div>
        <TopNavigation language={props.language} setLanguage={props.setLanguage} region={props.region}/>
        <AppLayout
            breadcrumbs={<BreadcrumbGroup
                items={[
                    { text: "Lotus Code", href: window.lotus },
                    {
                        text: "Brief Description of Our Infrastructure On AWS",
                        href: "/aws"
                    }
                ]}
                ariaLabel="Breadcrumbs"
            />}
            contentHeader={
                <Grid
                    gridDefinition={[{ colspan: 8 }, { colspan: 6 }]}
                >
                    <div><Header
                        variant="h1"
                        description={props.setLanguage=="ro"? "A Brief Description of Our Infrastructure On AWS":"A Brief Description of Our Infrastructure On AWS"}                        
                    >
                        AWS Cloud Infrastructure
                    </Header>
                    </div>
                    <div></div>
                </Grid>}
            navigation={<SideNavigation language={props.language}  setLanguage={props.setLanguage}></SideNavigation>}
            toolsHide="false"
            content={content[props.language]}>
        </AppLayout>
        </div>
    );
}