
export const APIGATE_SERVICEENDPOINT="https://5vjfbhrmb7.execute-api.eu-west-1.amazonaws.com/live";
//export const APIGATE_SERVICEENDPOINT="https://5vjfbhrmb7.execute-api.eu-west-1.amazonaws.com/live";

export const API_SERVICEENDPOINT="https://medapi.lotuscode.eu";
export const API_SERVICEENDPOINT_LAB="https://webapiv1.lotuscode.eu";

//export const API_SERVICEENDPOINT="https://medapi.lotuscode.eu";
//export const API_SERVICEENDPOINT_LAB="https://webapiv1.lotuscode.eu"

