var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _this = this;
import * as React from "react";
import { useState, useCallback, useRef } from "react";
import { Container, Button, Form, Row, Col, ListGroup, Card, Alert, Spinner, ProgressBar } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import ro from 'date-fns/locale/ro';
registerLocale('ro', ro);
import { API_SERVICEENDPOINT } from '../../constants/endpoints';
import ReCAPTCHA from "react-google-recaptcha";
import * as request from 'superagent';
import 'moment/locale/ro';
import * as moment from 'moment';
var OnlineAppointment = function () {
    var _a = useState(0), inputStage = _a[0], setInputStage = _a[1];
    var captchaRef = useRef(null);
    var _b = useState(""), captcha = _b[0], setCaptcha = _b[1];
    var _c = useState(""), inputLastName = _c[0], setInputLastName = _c[1];
    var _d = useState(""), inputFirstName = _d[0], setInputFirstName = _d[1];
    var _e = useState(""), inputPhone = _e[0], setInputPhone = _e[1];
    var _f = useState(""), inputEmail = _f[0], setInputEmail = _f[1];
    var _g = useState(new Date()), inputSelectedDate = _g[0], setInputSelectedDate = _g[1];
    var _h = useState(false), inputisSelectedDate = _h[0], setIsInputSelectedDate = _h[1];
    var _j = useState(false), validated = _j[0], setValidated = _j[1];
    var _k = useState(""), message = _k[0], setMessage = _k[1];
    var _l = useState(false), specialityLoading = _l[0], setSpecialityLoading = _l[1];
    var _m = useState(""), confirm = _m[0], setConfirm = _m[1];
    var _o = useState(false), confirmVisible = _o[0], setConfirmVisible = _o[1];
    var _p = useState([]), inputResources = _p[0], setInputResources = _p[1];
    var _q = useState([]), inputSpecialities = _q[0], setInputSpecialities = _q[1];
    var _r = useState([]), inputServices = _r[0], setInputServices = _r[1];
    var _s = useState([]), inputWorkingDays = _s[0], setInputWorkingDays = _s[1];
    var _t = useState([]), inputWorkingHours = _t[0], setInputWorkingHours = _t[1];
    var _u = useState(""), inputSelectedSpeciality = _u[0], setInputSelectedSpeciality = _u[1];
    var _v = useState(""), inputSelectedPhysicianName = _v[0], setInputSelectedPhysicianName = _v[1];
    var _w = useState(""), inputSelectedPhysicianCode = _w[0], setInputSelectedPhysicianCode = _w[1];
    var _x = useState(""), inputSelectedServiceName = _x[0], setInputSelectedServiceName = _x[1];
    var _y = useState(""), inputSelectedServiceCode = _y[0], setInputSelectedServiceCode = _y[1];
    var _z = useState(""), inputSelectedInterval = _z[0], setInputSelectedInterval = _z[1];
    var onFirstAction = useCallback(function (inputLastName, inputFirstName, inputPhone, captcha, inputEmail) { return function () { return __awaiter(void 0, void 0, void 0, function () {
        var error;
        return __generator(this, function (_a) {
            error = "";
            if (inputLastName == "")
                error += " Nume ";
            if (inputFirstName == "")
                error += " Prenume ";
            if (inputFirstName == "")
                error += " Nume ";
            if (inputLastName == "")
                error += " Prenume ";
            if (inputPhone == "")
                error += " Numar de telefon ";
            else {
                if (inputPhone.length != 10) {
                    error += " Numarul de telefon nu este complet ";
                }
            }
            if (error) {
                setValidated(true);
                setMessage(error);
            }
            else {
                setSpecialityLoading(true);
                setMessage("");
                request
                    .post(API_SERVICEENDPOINT + '/api/online/Speciality')
                    .set('Content-Type', 'application/json;charset=UTF-8')
                    .set('Access-Control-Allow-Origin', '*')
                    .set("captcha", captcha)
                    .end(function (err, res) {
                    if (res != null) {
                        var obj = JSON.parse(res.text);
                        setInputSpecialities(obj);
                        setInputStage(1);
                        setSpecialityLoading(false);
                    }
                });
            }
            return [2 /*return*/];
        });
    }); }; }, []);
    var onSpecialityAction = useCallback(function (name) { return function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setInputSelectedSpeciality(name);
            request
                .post(API_SERVICEENDPOINT + '/api/online/Resources')
                .set('Content-Type', 'application/json;charset=UTF-8')
                .set('Access-Control-Allow-Origin', '*')
                .set('name', name)
                .end(function (err, res) {
                if (res != null) {
                    var obj = JSON.parse(res.text);
                    setInputResources(obj);
                    setInputStage(2);
                }
            });
            return [2 /*return*/];
        });
    }); }; }, []);
    var onResourceAction = useCallback(function (name, code) { return function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setInputSelectedPhysicianCode(code);
            setInputSelectedPhysicianName(name);
            request
                .post(API_SERVICEENDPOINT + '/api/online/Services')
                .set('Content-Type', 'application/json;charset=UTF-8')
                .set('code', code)
                .end(function (err, res) {
                if (res != null) {
                    var obj = JSON.parse(res.text);
                    setInputServices(obj);
                    setInputStage(3);
                }
            });
            return [2 /*return*/];
        });
    }); }; }, []);
    var onServicesAction = useCallback(function (name, code, inputSelectedPhysicianCode, inputSelectedServiceCode) { return function () { return __awaiter(void 0, void 0, void 0, function () {
        var d;
        return __generator(this, function (_a) {
            setInputSelectedServiceCode(code);
            setInputSelectedServiceName(name);
            d = new Date();
            request
                .post(API_SERVICEENDPOINT + '/api/online/GetWorkingDays')
                .set('Content-Type', 'application/json;charset=UTF-8')
                .set('Access-Control-Allow-Origin', '*')
                .set('code', inputSelectedPhysicianCode)
                .set('service', inputSelectedServiceCode)
                .set('month', (d.getMonth() + 1).toString())
                .set('year', d.getFullYear().toString())
                .end(function (err, res) {
                if (res != null) {
                    var obj = JSON.parse(res.text);
                    var obj1_1 = [];
                    obj.forEach(function (element) {
                        obj1_1.push(new Date(element));
                    });
                    setInputWorkingDays(obj1_1);
                    setInputStage(4);
                }
            });
            return [2 /*return*/];
        });
    }); }; }, []);
    var onWorkingHourAction = useCallback(function (code, inputSelectedServiceCode, inputLastName, inputFirstName, inputPhone, inputSelectedPhysicianCode, inputSelectedDate, captcha, inputEmail) { return function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (captcha == "") {
                alert("Bifati casuta de la punctul 1 !");
            }
            else {
                setInputSelectedInterval(code);
                request
                    .post(API_SERVICEENDPOINT + '/api/online/Finish')
                    .set('Content-Type', 'application/json;charset=UTF-8')
                    .set('Access-Control-Allow-Origin', '*')
                    .set('code', inputSelectedPhysicianCode)
                    .set('datac', moment(inputSelectedDate).format("YYYY-MM-DD"))
                    .set('interval', code)
                    .set('service', inputSelectedServiceCode)
                    .set('lastname', inputLastName)
                    .set('firstname', inputFirstName)
                    .set('phone', "+4" + inputPhone)
                    .set('email', inputEmail)
                    .set("captcha", captcha)
                    .end(function (err, res) {
                    if (res != null) {
                        var obj = JSON.parse(res.text);
                        if (obj.Status == "OK") {
                            setConfirm(obj.Message);
                            setInputStage(5);
                        }
                        else {
                            alert("Eroare incercati din nou ! " + obj.Message);
                        }
                    }
                });
            }
            return [2 /*return*/];
        });
    }); }; }, []);
    var onFinishAction = useCallback(function (d) { return function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setConfirm(d);
            setInputStage(5);
            setConfirmVisible(true);
            return [2 /*return*/];
        });
    }); }; }, []);
    var specialites = inputSpecialities.map(function (item) {
        return (React.createElement(ListGroup.Item, { key: item.code, onClick: onSpecialityAction(item.name).bind(_this), action: true },
            " ",
            React.createElement("div", { className: "fw-bold" },
                " ",
                React.createElement("strong", null, item.name))));
    });
    var resources = inputResources.map(function (item) {
        return (React.createElement(ListGroup.Item, { key: item.code, onClick: onResourceAction(item.name, item.code).bind(_this), action: true },
            React.createElement("div", { className: "fw-bold" },
                " ",
                React.createElement("strong", null, item.name)),
            " "));
    });
    var services = inputServices.map(function (item) {
        return (React.createElement(ListGroup.Item, { as: "li", className: "d-flex justify-content-between align-items-start", key: item.code, onClick: onServicesAction(item.name, item.code, inputSelectedPhysicianCode, inputSelectedServiceCode).bind(_this), action: true },
            React.createElement("div", { className: "ms-2 me-auto" },
                React.createElement("div", { className: "fw-bold" },
                    React.createElement("strong", null,
                        " ",
                        item.name)),
                "Durata:",
                item.duration,
                " minute Cost: ",
                item.cost)));
    });
    var workinghours = inputWorkingHours.map(function (item) {
        return (React.createElement(React.Fragment, null,
            React.createElement(Button, { style: { background: "#00a3c8", padding: "10px" }, onClick: onWorkingHourAction(item, inputSelectedServiceCode, inputLastName, inputFirstName, inputPhone, inputSelectedPhysicianCode, inputSelectedDate, captcha, inputEmail) }, item),
            ' '));
    });
    return (React.createElement(Container, null,
        React.createElement("br", null),
        React.createElement("br", null),
        React.createElement("br", null),
        React.createElement(ProgressBar, { danger: true, striped: true, variant: "danger", now: inputStage * 20 }),
        inputStage == 0 &&
            React.createElement(Card, { style: { width: "100%" } },
                React.createElement(Card.Header, { as: "h5" }, "Programare Online "),
                React.createElement(Card.Body, null,
                    React.createElement(Row, null,
                        React.createElement(Form, { style: { width: "100%" }, noValidate: true, validated: validated },
                            React.createElement("br", null),
                            React.createElement("br", null),
                            React.createElement(Form.Group, { className: "mb-3" },
                                React.createElement(Form.Label, null,
                                    React.createElement("strong", null, "Nume")),
                                React.createElement(Form.Control, { required: true, onChange: function (e) { setInputLastName(e.target.value); }, placeholder: "Nume" })),
                            React.createElement(Form.Group, { className: "mb-3" },
                                React.createElement(Form.Label, null,
                                    React.createElement("strong", null, "Prenume")),
                                React.createElement(Form.Control, { required: true, onChange: function (e) { setInputFirstName(e.target.value); }, placeholder: "Prenume" })),
                            React.createElement(Form.Group, { className: "mb-3" },
                                React.createElement(Form.Label, null,
                                    React.createElement("strong", null, "Telefon")),
                                React.createElement(Form.Control, { type: "number", required: true, onChange: function (e) { setInputPhone(e.target.value); }, placeholder: "Telefon" }),
                                React.createElement(Form.Text, { className: "text-muted" },
                                    inputPhone.length,
                                    "/10 caractere.")),
                            React.createElement(Form.Group, { className: "mb-3" },
                                React.createElement(Form.Label, null,
                                    React.createElement("strong", null, "Email *optional")),
                                React.createElement(Form.Control, { type: "email", required: true, onChange: function (e) { setInputEmail(e.target.value); }, placeholder: "Email" })),
                            React.createElement("br", null),
                            React.createElement("br", null))),
                    React.createElement("br", null),
                    React.createElement(Row, null, message.length > 0 &&
                        React.createElement(Alert, { variant: "danger" }, message)),
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 5 }),
                        React.createElement(Col, { xs: 7 },
                            !specialityLoading &&
                                React.createElement(Button, { style: { background: "#00a3c8" }, variant: "primary", onClick: onFirstAction(inputLastName, inputFirstName, inputPhone, captcha, inputEmail).bind(_this) },
                                    React.createElement("i", { className: "fa fa-play " }),
                                    " Alege specialitatea"),
                            specialityLoading &&
                                React.createElement(Spinner, { animation: "border", role: "status" }))))),
        inputStage == 1 &&
            React.createElement("div", null,
                React.createElement("br", null),
                React.createElement(Card, null,
                    React.createElement(Card.Header, { as: "h5" }, "Alege Specialitatea"),
                    React.createElement(Card.Body, null,
                        React.createElement(ListGroup, null, specialites)),
                    React.createElement(Card.Body, null,
                        React.createElement(Button, { style: { background: "#00a3c8" }, variant: "primary", onClick: function () { setInputStage(0); } },
                            React.createElement("i", { className: "fa fa-chevron-circle-left" }),
                            " Inapoi")))),
        inputStage == 2 &&
            React.createElement("div", null,
                React.createElement("br", null),
                React.createElement(Card, null,
                    React.createElement(Card.Header, { as: "h5" },
                        "Alege medicul pentru ",
                        inputSelectedSpeciality),
                    React.createElement(Card.Body, null,
                        React.createElement(ListGroup, null, resources)),
                    React.createElement(Card.Body, null,
                        React.createElement(Button, { style: { background: "#00a3c8" }, variant: "primary", onClick: function () { setInputStage(1); } },
                            React.createElement("i", { className: "fa fa-chevron-circle-left" }),
                            " Inapoi")))),
        inputStage == 3 &&
            React.createElement("div", null,
                React.createElement("br", null),
                React.createElement(Card, null,
                    React.createElement(Card.Header, { as: "h5" }, "Alege serviciul medical "),
                    React.createElement(Card.Body, null,
                        React.createElement(ListGroup, null, services)),
                    React.createElement(Card.Body, null,
                        React.createElement(Button, { style: { background: "#00a3c8" }, variant: "primary", onClick: function () { setInputStage(2); } },
                            React.createElement("i", { className: "fa fa-chevron-circle-left" }),
                            " Inapoi")))),
        inputStage == 4 &&
            React.createElement("div", null,
                React.createElement("br", null),
                React.createElement(Card, null,
                    React.createElement(Card.Body, null,
                        React.createElement(Card.Title, null, "1. Bifati casuta de mai jos"),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 4 }),
                            React.createElement(Col, { xs: 4 },
                                React.createElement(ReCAPTCHA, { ref: captchaRef, hl: "ro", sitekey: "6Ld9MO4ZAAAAALl4VT7Rdy6Mot2q6fLxu42jEcDg", onChange: setCaptcha })))),
                    React.createElement(Card.Body, null,
                        React.createElement(Card.Title, null, "2.Alegeti data la care doriti programarea"),
                        React.createElement(Card.Text, null,
                            React.createElement("table", null,
                                React.createElement("tbody", null,
                                    React.createElement("tr", null,
                                        React.createElement("td", { style: { textAlign: "center" } },
                                            React.createElement(DatePicker, { locale: "ro", minDate: new Date(), maxDate: moment().add(60, 'days').toDate(), excludeDates: inputWorkingDays, inline: true, placeholderText: "DD/MM/YYYY", selected: inputSelectedDate, onChange: function (e) {
                                                    setInputSelectedDate(e);
                                                    setIsInputSelectedDate(true);
                                                    request
                                                        .post(API_SERVICEENDPOINT + '/api/online/GetWorkingHours')
                                                        .set('Content-Type', 'application/json;charset=UTF-8')
                                                        .set('code', inputSelectedPhysicianCode)
                                                        .set('service', inputSelectedServiceCode)
                                                        .set('datac', moment(e).format("YYYY-MM-DD"))
                                                        .end(function (err, res) {
                                                        if (res != null) {
                                                            var obj = JSON.parse(res.text);
                                                            setInputWorkingHours(obj);
                                                        }
                                                    });
                                                } }))),
                                    React.createElement("tr", null,
                                        React.createElement("td", null, inputisSelectedDate &&
                                            React.createElement(Card, { style: { width: '100%' } },
                                                React.createElement(Card.Body, null,
                                                    React.createElement("div", { style: { height: "100%", width: "100%" } },
                                                        "Ati selectat data de :",
                                                        moment(inputSelectedDate).format("DD/MM/YYYY"),
                                                        React.createElement("br", null),
                                                        React.createElement("table", null,
                                                            React.createElement("tbody", null,
                                                                React.createElement("tr", null,
                                                                    workinghours.length > 0 &&
                                                                        React.createElement("div", { style: { paddingTop: "10px" } }, workinghours),
                                                                    workinghours.length == 0 &&
                                                                        React.createElement(Alert, { variant: "danger" }, "Nu mai avem locuri pentru programari la aceasta data!"))))))))))))),
                    React.createElement(Card.Body, null),
                    React.createElement(Card.Body, null,
                        React.createElement(Button, { style: { background: "#00a3c8" }, variant: "primary", onClick: function () { setInputStage(3); setInputWorkingDays([]); setInputWorkingHours([]); setIsInputSelectedDate(false); } },
                            " ",
                            React.createElement("i", { className: "fa fa-chevron-circle-left " }),
                            " Inapoi ")))),
        inputStage == 5 &&
            React.createElement("div", null,
                React.createElement("br", null),
                React.createElement(Card, null,
                    React.createElement(Card.Header, { as: "h5" }, "Programare finalizata"),
                    React.createElement(Card.Body, null,
                        React.createElement(Row, null,
                            React.createElement("h3", null, "Programare finalizata cu success ! Multumim!")),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 1 }),
                            React.createElement(Col, null,
                                React.createElement(Row, null,
                                    "Numar confirmare: ",
                                    confirm),
                                React.createElement(Row, null,
                                    "Data: ",
                                    moment(inputSelectedDate).format("DD/MM/YYYY")),
                                React.createElement(Row, null,
                                    "Ora : ",
                                    inputSelectedInterval),
                                React.createElement(Row, null,
                                    "Medic : ",
                                    inputSelectedPhysicianName),
                                React.createElement(Row, null,
                                    "Specialitate : ",
                                    inputSelectedSpeciality))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 5 }),
                            React.createElement(Col, null,
                                React.createElement("img", { src: "/images/confirm.gif" }))))))));
};
export default OnlineAppointment;
