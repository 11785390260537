import React from 'react';
import { useState,useEffect } from "react";
import { BrowserRouter as Router,Switch, Route, Redirect } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import GSM from './layout/GSM/GSM';
import Services from './layout/GSM/Sevices';
import Contact from './layout/GSM/Contact';
import TagManager from 'react-gtm-module';

export default function AppLotus(props) {
  const [language, setLanguage] = useState(global.window.localStorage["lang"]==undefined?"ro":global.window.localStorage["lang"]);
  
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-MF5SRXS"
    }           
    TagManager.initialize(tagManagerArgs);
  }, []);
  
  let localhost = window.location.origin.toString();
  localhost=localhost.replace("site","www");
  window.lotus = localhost;
  
    return (
      <HelmetProvider>
        <Helmet>
        <title>TechXGSM Alba Iulia</title>
        <meta name="description" content="gsm alba iulia, gsm, telefoane, service gsm,reparatii telefoane alba, reparatii telefoane alba iulia,service telefoane alba ,service iphone alba , mobile phone, piese telefoane alba, inlocuire display alba, inlocuire ecran telefon" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="www.techxgsm.ro" />
        <meta property="og:title" content="TechXGSM Alba Iulia"/>
        <meta property="og:description" content="gsm alba iulia, gsm, telefoane, service gsm,reparatii telefoane alba, reparatii telefoane alba iulia,service telefoane alba ,service iphone alba , mobile phone, piese telefoane alba, inlocuire display alba, inlocuire ecran telefon"/>
        <link rel="canonical" href="www.techxgsm.ro" />           
        </Helmet>
     <div>                   
        <div>
          <Router>
          <Switch>        
                                                 
            <Route exact  path="/contact" render={(match) => (
                <Contact language="ro" setLanguage={setLanguage} ></Contact>
            )} />

            <Route exact  path="/services" render={(match) => (
                <Services language="ro" setLanguage={setLanguage} ></Services>
            )} />
           
            <Route exact  path="/" render={(match) => (
                <GSM language="ro" setLanguage={setLanguage} ></GSM>
            )} />

          </Switch>
          </Router>
        </div>
      </div>
      </HelmetProvider>
    );
}


