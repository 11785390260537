import React from "react";

const Aesthetics = (company) => {
  return (
    <div id="single-blog-page" className="wide-100 blog-page-section division">
      <div className="container">
        <div className="row">
          <div className="post-inner-img">
            <h4 className="h4-lg steelblue-color">
              Estetica dentara
            </h4>
          </div>

          <p>
          Estetica dentară s-a născut din dorinţa oamenilor de a avea un zâmbet cât mai frumos. Această specializare se adresează pacienţilor care-şi doresc dinţi sănătoşi, cât mai armonizaţi cu fizionomia lor şi nu în ultimul rând, cât mai albi. Imaginea generează succesul, iar o imagine frumoasă devine perfectă cu un zâmbet perfect.
          </p>   
          <p>
          Zâmbetul este una din expresiile chipului uman ce ne influenţează imaginea şi relaţiile cu cei din jur. Încrederea în sine, satisfacţia unui aspect plăcut şi carisma unei persoane joviale cucereşte de la prima vedere şi reprezintă un atu atât în mediul social cât şi în cel business. Dinţii albi vă luminează chipul, emanând buna dispoziţie şi entuziasm în jurul dumneavoastră.

Din fericire, cu ajutorul tehnologiei actuale, vă putem ajuta să dobândiţi un zâmbet spectaculos, mai alb şi mult mai tânr, aşa cum vi-l doriţi. Folosim cele mai noi tehnici şi materiale disponibile în prezent, iar medicii clinicii Romcea au cea mai bună calificare în acest domeniu.

Durata unui tratament de cosmetică dentară variază în funcţie de proceduri şi de persoană. Starea dinţilor dumneavoastră înainte de tratament influenţează durata tratamentelor.
            </p>   
          
            <p>
              <h4>{company.company.CONFC_Name} vă poate ajuta să obţineţi zâmbetul mult visat oferindu-vă :</h4>
            </p> 
            <p>
            Tratamente de albire: (Albire dentară profesională)
            </p> 
            <p>
            Anumiţi pigmenţi alimentari (cafea, tutun, ceai, vin roşu, etc), precum şi coloranţi ai dinţilor (ca tetraciclina, fluor în exces) pot produce modificări nedorite ale culorii acestora. Pentru obţinerea unei nuanţe satisfăcătoare o igienă riguroasă şi chiar igienizările profesionale sunt insuficiente.

Sistemul de albire cu lampa cu plasmă Beyond, oferit de {company.company.CONFC_Name}, dă rezultate remarcabile în doar o oră de tratament în cabinetul stomatologic. Procedura este inofensivă şi lipsită de disconfort iar timpul redus de tratament precum şi nuanţele foarte luminoase obţinute fac ca aceasta să fie foarte solicitată mai ales de pacienţii pentru care timpul este foarte preţios . De asemenea, albirea dentară cu lampă este foarte utilă şi indicată în cazul unor evenimente care cer un zâmbet fără cusur într-un interval de timp foarte scurt (căsătorii, party-uri, conferinţe, diverse evenimente mondene).

Evident, nici sistemele de albire la domiciliu nu şi-au pierdut din valoare, iar în toate situaţiile care necesită albire, igienizarea profesională cu cateva zile înainte este absolut obligatorie.

Nuanţele obţinute atât prin sistemele “take home”, cât şi prin cele “in office”, sunt de până la  8-10 ori mai luminoase decât cele iniţiale, iar rezultatul este garantat pentru 2-3 ani.conferinţe, diverse evenimente mondene).
            </p> 
            <p>
            Faţete ceramice: 
            </p>     
            <p>
            Sunt restaurările cele mai fizionomice, dar şi cele mai delicate. Se realizează prin tehnici de stratificare complexă determinată de ofertă minimă de spaţiu. Sunt avantajoase deoarece necesită şlefuiri minore ale suprafeţei exterioare a dinţilor (0.6mm), conservând  vitalitatea dinţilor. Sunt foarte fizionomice, neavând infrastructură metalică; dinţii restauraţi nu vor prezenta umbre negre în vecinătatea gingiei. Faţetele ceramice rescpectă textura şi transluciditatea dinţilor naturali.

Prepararea dinţilor, amprentarea şi cimentarea faţetelor sunt de asemenea proceduri extrem de sofisticate, care necesită personal medical rabdător, dedicat şi, înainte de toate, foarte calificat în domeniul esteticii dentare.
            </p>
            <p>
            Inaly-uri şi onlay-uri ceramice:
            </p>
            <p>
            Sunt proceduri stomatologice utilizate de un număr redus de medici stomatologi. În anumite cazuri, inlay-urile şi onlay-urile sunt o alternativă conservatoare la coroanele dentare cu acoperire totală. Cunoscute şi sub numele de “plombe indirecte” sau “incrustaţii”, inlay-urile şi onlay-urile oferă o soluţie reparatorie bine fixată, mai rezistentă şi cu durabilitate mai mare decât plombele din răşini compozite pentru cariile dentare sau problemele similare. Aceste restaurări sunt benefice atât din punct de vedere estetic cât şi funcţional.

Inlay-urile şi onlay-urile pot fi utilizate adesea în locul plombelor dentare tradiţionale, pentru tratarea cariilor dentare sau a deteriorărilor structurale similare. În timp ce plombele dentare sunt confecţionate direct în cavitatea dintelui în cadrul unei vizite stomatologice, inlay-urile şi onlay-urile sunt fabricate  într-un laborator dentar, înainte să fie ajustate şi lipite pe dintele deteriorat de către medicul stomatolog.

Inlay-urile şi onlay-urile oferă o pregătire conservatoare care menţine o suprafaţă cât mai mare de dinte sănătos. Acestea sunt o alegere excelentă dacă prezentaţi o carie minimă spre moderată a dinţilor, care se întinde într-o zona curăţată cu aţă dentară, oferind o alternativă excelentă la coroanele cu acoperire totală.



            </p>
            <p>
            Coroane  ceramice ( metalo-ceramice , integral ceramice)
            </p>
            <p>
            Coroanele dentare  păstrează funcţionalitatea dinţilor afectaţi. Coroanele pot fi utilizate pentru a proteja un dinte fisurat, pentru a reda funcţionalitatea unui dinte cariat excesiv sau pentru a înlocui o coroană existentă. Scopul unei coroane dentare este de a înveli un dinte cu probleme cu un material special creat. În ziua de azi, medicii stomatologi au la dispozitie o varietate de opţiuni de tratament conservator pentru restaurarea dinţilor. Dacă este posibil, aceste opţiuni trebuie analizate şi discutate înainte de selectarea coroanei cu acoperire totală.
            </p>
            <p>
            Tatuaje si bijuterii dentare.
            </p>
        </div>
        {/* End row */}
      </div>
      {/* End container */}
    </div>
  );
};

export default Aesthetics;
