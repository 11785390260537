export const LOGIN_SENDING = 'LOGIN_SENDING';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_ERR = 'LOGIN_USER_ERR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const USER_PROFILE = 'USER_PROFILE';
export const CHANGE_SELECTION = 'CHANGE_SELECTION';


export const GET_HOTELLIST = 'GET_HOTELLIST';
export const GET_HOTELLIST_LOADED = 'GET_HOTELLIST_LOADED';

export const GET_HOTELDETAIL = 'GET_HOTELDETAIL';
export const GET_HOTELDETAIL_LOADED = 'GET_HOTELDETAIL_LOADED';
export const GET_HOTELDETAIL_CITY_LOADED = 'GET_HOTELDETAIL_CITY_LOADED';
export const SAVE_HOTELDETAIL = 'SAVE_HOTELDETAIL';
export const DELETE_HOTELDETAIL = 'DELETE_HOTELDETAIL';


