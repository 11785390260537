import React from 'react';
import { useState } from "react";
import SideNavigation from "@cloudscape-design/components/side-navigation";
import { propTypes } from 'react-bootstrap/esm/Image';


export default function Code(props) {
    const [value, setValue] = useState("");
    const [activeHref, setActiveHref] = React.useState(
        window.location.pathname
    );


    return (
        <SideNavigation
            activeHref={activeHref}
            header={{ href: "/", text: "Lotus Code", logo: { alt: "logo", src: "images/logo.png" } }}
            onFollow={event => {
                if (!event.detail.external) {
                    event.preventDefault();
                    setActiveHref(event.detail.href);
                    location.assign(window.lotus + event.detail.href);
                }
            }}
            items={[
                { type: "link", text: props.language == "ro" ? "Home" : "Home", href: "/" },
                { type: "divider" },
                { type: "link", text: "Lotus Med", href: "/lotusmed" },
                { type: "divider" },
                { type: "link", text: "Lotus Lab", href: "/lotuslab" },
                { type: "divider" },
                { type: "link", text: "Lotus RX24", href: "/lotusrx24" },
                { type: "divider" },
                { type: "link", text: "Lotus Factory", href: "/lotusfactory" },
                { type: "divider" },
                { type: "link", text: "Contact", href: "/contact" },

            ]}
        />
    );
}