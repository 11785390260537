import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import * as request from 'superagent';
import { API_SERVICEENDPOINT_LAB } from '../../../constants/endpoints';
import ReactTable from "../UI/ReactTable";
var ServiceListLab = function (company) {
    var _a = useState([]), services = _a[0], setServices = _a[1];
    useEffect(function () {
        request
            .post(API_SERVICEENDPOINT_LAB + '/api/online/Services')
            .set('Content-Type', 'application/json;charset=UTF-8')
            .set('Access-Control-Allow-Origin', '*')
            .end(function (err, res) {
            if (res != null) {
                var obj = JSON.parse(res.text);
                console.log(obj);
                setServices(obj);
            }
        });
    }, []);
    var columns = useMemo(function () { return [
        {
            Header: "Lista Investigatii Laborator",
            columns: [
                {
                    Header: "Denumire investigatie",
                    accessor: "LAIV_Name",
                },
                {
                    Header: "Pret investigatie",
                    accessor: "LAIV_Price",
                },
                {
                    Header: "Decontat de CNAS",
                    accessor: "LAIV_PriceCNAS",
                }
            ]
        }
    ]; }, []);
    return (React.createElement("section", { id: "contacts-1", className: "wide-60 contacts-section division" },
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-lg-10 offset-lg-1 section-title" },
                    React.createElement("h3", { className: "h3-md steelblue-color" }, "Investigatii laborator"))),
            React.createElement(ReactTable, { columns: columns, data: services }))));
};
export default ServiceListLab;
