
import * as React from "react";
import { useTable,useFilters,useSortBy} from "react-table";
import { Table } from 'react-bootstrap';
import CustomInput from "../UI/ReactTableCustomInput";
import styled from 'styled-components'

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;
    width:100%;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`

const ColumnFilter = ({ column: { filterValue, setFilter, filter } }) => {
  return (
    <CustomInput
      value={filterValue || ""}
      onChange={e => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Cauta ${filter ? filter : ""}...`}
    />
  );
};

const ReactTable = ({ columns, data }) => {
    // functions to run when a column is filtered depending on the type
    const filterTypes = {
      year: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined &&
            Number(filterValue) &&
            new Date(rowValue) &&
            new Date(rowValue).isValid()
            ? new Date(rowValue).getFullYear() === Number(filterValue)
            : true;
        });
      },
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      }
    };
    const defaultColumn = {
      // Let's set up our default Filter UI
      Filter: ColumnFilter
    };
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        filterTypes,  
      },
      // hook for filters
      useFilters,
      // hook for sorting
      useSortBy
    );
    
    
    
    
    return (
      <Styles>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => {
                // three new addition to column: isSorted, isSortedDesc, getSortByToggleProps
                const {
                  render,
                  getHeaderProps,
                  isSorted,
                  isSortedDesc,
                  getSortByToggleProps,
                  // filter,
                  canFilter
                } = column;
                const extraClass = isSorted
                  ? isSortedDesc
                    ? "desc"
                    : "asc"
                  : "";
                return (
                  <th
                    {...column.getHeaderProps()}
                    key={`th-${i}`}
                    className={extraClass}
                    style={{width:column.width}}
                    // getHeaderProps now receives a function
                  >
                    <div {...getHeaderProps(getSortByToggleProps())}>
                      {render("Header")} 
                    </div>
                    {/* Render the columns filter UI */}
                    <div>{canFilter ? render("Filter") : null}</div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.length==0 &&
          <div><center>Nu exista date pentru afisare</center></div>}
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      </Styles>
    );
  };

export default ReactTable;