import React from 'react';
import { useState } from "react";
import Header from "@cloudscape-design/components/header";
import Container from "@cloudscape-design/components/container";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Input from "@cloudscape-design/components/input";
import Button from "@cloudscape-design/components/button";
import TopNavigation from "./TopNavigation"
import AppLayout from "@cloudscape-design/components/app-layout";
import Badge from "@cloudscape-design/components/badge";
import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";
import TextContent from "@cloudscape-design/components/text-content";
import Grid from "@cloudscape-design/components/grid";
import ColumnLayout from "@cloudscape-design/components/column-layout";
import SideNavigation from './Sidenavigation';
import TagManager from 'react-gtm-module'
import Link from "@cloudscape-design/components/link";
import 'photoswipe/dist/photoswipe.css'
//import { Gallery, div } from 'react-photoswipe-gallery'
import Gallery from "react-photo-gallery";
export const photos = [
    {
      src: "https://lotusdownload.s3.eu-west-1.amazonaws.com/Images/techxgsm/8.jpeg",
      width: 1,
      height: 1
    },
    {
        src: "https://lotusdownload.s3.eu-west-1.amazonaws.com/Images/techxgsm/10.jpeg",
        width: 1,
        height: 1
      },
      {
        src: "https://lotusdownload.s3.eu-west-1.amazonaws.com/Images/techxgsm/11.jpeg",
        width: 1,
        height: 1
      }
  ];

  export const photos1 = [
    {
      src: "https://lotusdownload.s3.eu-west-1.amazonaws.com/Images/techxgsm/19.jpeg",
      width: 1,
      height: 1
    },
    {
        src: "https://lotusdownload.s3.eu-west-1.amazonaws.com/Images/techxgsm/20.jpeg",
        width: 1,
        height: 1
      }
  ];

export default function Code(props) {
    const [value, setValue] = useState("");
    const [menu, setMenu] = useState(false);

    const [activeHref, setActiveHref] = React.useState(
        "#/page1"
    );


    let content = {
        "ro": <SpaceBetween size="m">
            <Container>
                <TextContent>
                    <h1>SERVICII PRESTATE</h1>
                    <hr></hr>
                    <p>Pentru depanarea dispozitivelor de telefonie mobile intervenim pe placa de baza cu echipamente de ultima generatie ceea ce duce la performanțe crescute  minim invazive  și calitative în ceea ce privește repararea hardware a telefoanelor mobile</p>
                    <p>Efectuam reparatii hardware în vederea remedierii  oricărei defecțiuni a dispozitivelor dumneavoastra cu personal autorizat și calificat în depanarea dispozitivelor de telefonie mobilă.</p>
                    <center>
                        <table>
                        <tr>
                        <td>
                        <video width="320" height="240" controls>
                        <source src="https://lotusdownload.s3.eu-west-1.amazonaws.com/Images/techxgsm/Video1.mp4" type="video/mp4"/>
                        Your browser does not support the video tag.
                        </video>
                        </td>
                        <td>
                        <video width="320" height="240" controls>
                        <source src="https://lotusdownload.s3.eu-west-1.amazonaws.com/Images/techxgsm/Video2.mp4" type="video/mp4"/>
                        Your browser does not support the video tag.
                        </video>
                        </td>
                        </tr>
                        </table>                        
                    </center>
                
                </TextContent>
            </Container>
            <Container>
                <TextContent>
                    <h1>REPARAȚII  HARDWARE</h1>
                    <hr></hr>
                    <p>Inlocuire (depanare) module încărcare și alimentare</p>
<p>Depanare sau inlocuire module audio (căști,microfoane ,sonerii), inlocuim cipuri audio pentru ultimele modele de telefoane de pe piața.</p>
<p>Inlocuire module Display (ecrane) pentru orice model de dispozitiv cu piese de calitate înaltă și piese originale în funcție de cerințele clientului sau necesitățile dispozitivului.</p>
<p>Inlocuire module touchscreen cu sau fără display integrat pentru o gama largă de tablete.</p> 
<p>Reparatie module de semnal și radiofrecventa pentru dispozitivele de telefonie mobilă.</p>
<br></br>
                    <center>
                        <div>
                        <Gallery photos={photos} />     
                        </div>
                    </center>
                    <br></br>

                </TextContent>
            </Container>
            <Container>
                <TextContent>
                    <h1>REPARAȚII  SOFTWARE</h1>
                    <hr></hr>
                    <p>Deblocare (decodare) terminale blocate/codate în dif. rețele mobile.</p>
<p>Resoftare si devirusare telefoane cu diferite probleme legate de spațiu de stocare/memorie sau virusate .</p>
<p>Executăm recuperare de date(contacte, poze ,notițe ,etc.)din  telefoane deteriorate.</p>
<p>Executăm transferuri  de date între dispozitivul vechi și cel nou în condiții de maximă siguranță avand grije ca informațiile transferate sa ramana intacte.</p>
<center>                <br></br>
                        <div>
                        <Gallery photos={photos1} />                             
                        </div>
                    </center>
                </TextContent>
            </Container>           
        </SpaceBetween>        
    }


    return (
        <div>
            <TopNavigation language={props.language} setLanguage={props.setLanguage}
            />
            <AppLayout
                breadcrumbs={<BreadcrumbGroup
                    divs={[
                        { text: "TechXGSM", href: window.lotus },
                        {
                            text: "Acasa",
                            href: "/"
                        }
                    ]}
                    ariaLabel="Breadcrumbs"
                />}
                contentHeader={
                    <div>
                        <Grid
                            gridDefinition={[{ colspan: 8 }, { colspan: 6 }]}
                        >
                            <div><Header
                                variant="h1"
                                description={"Telefonul tău are nevoie de o reparație? Suntem aici pentru tine cu servicii profesionale şi de calitate."}
                            >
                                TechXGSM
                            </Header>
                            </div>
                            <div></div>
                        </Grid>
                    </div>}
                navigation={<SideNavigation language={props.language} setLanguage={props.setLanguage}></SideNavigation>}
                navigationOpen={menu}
                onNavigationChange={(val) => { setMenu(val.detail.open); }}
                toolsHide="false"
                contentType="form"
                content={content[props.language]
                }>

            </AppLayout>
        </div>
    );
}