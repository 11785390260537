import React from "react";
import { Button} from 'react-bootstrap';
import parse from 'html-react-parser';
import ReactWOW from 'react-wow'

const Demo1Hero1 = (company) => {
    
   
    return (
        <section id="hero-1" className="bg-fixed hero-section division">
            <div className="container">
                <div className="row d-flex align-items-center">
                    {/* HERO TEXT */}
                    <div className="col-md-8 col-lg-7 col-xl-6">
                        <div className="hero-txt mb-40">
                            {/* Title */}
                            <h5 className="steelblue-color">
                                Bine Ati Venit 
                            </h5>
                            <ReactWOW animation='slideInLeft'>
                            <h2 className="steelblue-color wow fadeInUp">
                                {company.company.CONFC_Name}
                            </h2>
                            </ReactWOW>
                            {/* Text */}
                            {parse(company.company.CONFC_AboutCompany)}
                            
                            {/* Button */}
                            {company.company.type=="med" &&
                            <ReactWOW animation='pulse'>
                            {company.company.CONFC_OnlineAppointment==1 &&
                            <Button  className="btn btn-blue" href={window.lotus+"/Appointment"}>
                                    Programare online 
                            </Button>
                            }
                            </ReactWOW>
                            }
                        </div>
                    </div>
                    {/* END HERO TEXT */}
                    {/* HERO IMAGE */}
                    <div className="col-md-4 col-lg-5 col-xl-6">
                        <div className="hero-1-img text-center">                           
                            {company.company.CONFC_ImageMainPage==null &&
                            <img
                                className="img-fluid"
                                src="public/images/hero-1-img.png"
                                alt="hero-image"
                            />
                            }
                            {company.company.CONFC_ImageMainPage!=null &&
                            <img
                                className="img-fluid"                                
                                src={"https://lotusdownload.s3.eu-west-1.amazonaws.com/Images/"+company.company.CONFC_ImageMainPage+".png"}
                                alt="hero-image"
                            />
                            }
                        </div>
                    </div>
                </div>
                {/* End row */}
            </div>
            {/* End container */}
        </section>
    );
};

export default Demo1Hero1;
