import React from "react";

const Gdpr = (company) => {
  return (
    <div id="single-blog-page" className="wide-100 blog-page-section division">
      <div className="container">
        <div className="row">
        <div className="post-inner-img">
        <h4 className="h4-lg steelblue-color">
        Politica de Confidentialitate - NOTĂ DE INFORMARE
                </h4>
                </div>
       
<p >
În această secțiune găsiți informaţii complete cu privire la modul de prelucrare a datelor dumneavoastră cu caracter personal și drepturile pe care le aveți, în conformitate cu legislaţia privind protecţia datelor.
</p>
<p >
1. Responsabilul cu prelucrarea datelor cu caracter personal este  {company.company.CONFC_Name}, cu sediul în: Alba Iulia, {company.company.CONFC_Address} , cod fiscal {company.company.CONFC_FiscalCode}, email: {company.company.CONFC_Email}
</p>
<p >
2. Tipurile de date prelucrate sunt:
</p>
<p >
 Prelucrăm următoarele categorii de date pe care ni le furnizați dumeavoastră sau, acolo unde este cazul, angajatorul dumneavoastră:
</p>
<p >

a) date de contact, cum ar fi:  numele, prenumele, domiciliul, numărul de telefon și / sau adresa de email:
</p>
<p >

b) date înscrise în actul de identitate, cum ar fi: data nașterii, sexul, CNP
</p>
<p >

c) date medicale(care sunt incluse în categoriile speciale de date cu caracter personal), cum ar fi: diagnostic, rezultatele analizelor pe care le efectuăm sau pe care ni le puneți la dispoziție, recomandări medicale, simptome, istoric medical, mostre biologice, precum și orice alte informații sau date cu caracter medical pe care dumneavoastră ni le furnizați, biletul de trimitere
</p>
<p >
d) date referitoare la situația profesională, cum ar fi: angajatorul curent, viitorul angajator, ocupația / poziția dumneavoastră
</p>
<p >
e) date   referitoare  la  calitatea   dumneavoastră   de  asigurat, cum ar fi codul de asigurat,  asta în cazul în care analizele efectuate sunt decontate prin sistemul public de asigurări de sănătate
</p>
<p >
f) date generate intern cum ar fi: cod unic al fișei de analize, codul din registrul unic de evidență, codul buletinului de analize
</p>
<p >
g) semnătura.
</p>
<p >
</p>
<p>
Dorim să precizăm că, în principiu, nu există nicio obligație pentru dumeavoastră de a ne furniza datele solicitate. Cu toate acestea, în cazul unui refuz, vă rugăm să luați act de faptul că nu vom putea să vă punem la dispoziție serviciile medicale pe care le oferim și nici nu veți putea beneficia de facilitățile pe care  {company.company.CONFC_Name} le oferă clienților săi (de ex. neindicarea numărului de telefon sau a unei adrese de mail, ne pune în imposibilitatea de a vă comunica eventualele progrămări sau modificări ale acestora, de a vă confirma finalizarea analizelor, de a vă transmite rezultatele serviciilor medicale pe cale electronică).
</p>
<p>
2. Temeiul și scopurile de prelucrare a datelor
</p>
<p>
2.1. Executarea unui contract / Prestarea  serviciilor  medicale: Prin prelucrarea datelor avem în vedere efectuarea de analize medicale și prestarea serviciilor medicale astfel cum sunt solicitate de către dumneavoastră, prestarea serviciilor de medicina muncii, stabilirea unui diagnostic dar și comunicarea cu dumneavoastră cu privire la serviciile medicale furnizate și transmiterea rezultatelor.
</p>
<p> 
2.2. Respectarea unei obligații legale: În primul rând,  {company.company.CONFC_Name} este ținută să respecte normele privind eliberarea documentelor financiar-contabile (bonuri sau facturi fiscale), încasarea plăților pentru servicii, recuperarea debitelor (ceea ce poate presupune si implicarea unor terte parti pentru recuperarea creantelor), restituirea unor sume de bani către dumneavoastră.
</p>
<p> 
În al doilea rând,  {company.company.CONFC_Name} trebuie să se supună unor obligații cu privire la păstrarea datelor, ținerea unor evidențe pentru raportările în față autorităților și instituților publice cu atribuții de control în domeniu.
</p>
<p> 
2.3. Interesul legitim al  {company.company.CONFC_Name}: Prin prelucrarea datelor urmărim și identificarea problemelor sau a unor posibilele chestiuni relevante referitoare la serviciile existente în scopul de a le îmbunăți, precum și de a soluționa eventualele sesizări formulate de dumneavoastră.
</p>
<p> 
3. Destinatarii datelor
</p>
<p> 
În măsura în care, în conformitate cu prevederile legale în vigoare, există obligația ca noi să transmitem date, sau acestea ne sunt solicitate de diverse organe și/sau autorități publice (cum ar fi, dar fără a se limita la: instanțe de judecată, Casa Națională a Asigurărilor de Sănăte și orice alte instituții și/sau autorității de supraveghere și control),  {company.company.CONFC_Name} se va conforma, fără a vă mai solicita acordul în acest sens.
</p>
<p> 
Datele vor putea fi transmise și către angajatorul dumneavoastră, în cazul în care prelucrarea se face în contextul medicinei muncii. În acest caz, vom comunica exclusiv acele date care sunt legate de acest scop.
</p>
<p> 
Pentru prestarea serviciilor,  {company.company.CONFC_Name} poate apela și la alți furnizorii de servicii medicale, parteneri. În acest sens, vă informăm că datele privind starea de sănătate, sau probele biologice, vor putea fi comunicate și către respectivii furnizori, însă cu asigurarea respectării confidențialității și securității datelor.
</p>
<p> 
Datele dumneavoastră cu caracter personal nu sunt transferate în afara Spațiului Economic European. 
</p>
<p> 
4. Durata de stocare a datelor
</p>
<p> 
În condițiile în care nu există prevederi legale specifice privind stocarea,  {company.company.CONFC_Name} păstrează datele prelucrate cu ocazia prestării serviciilor medicale, pentru o perioadă de 3 ani, după care se șterg.
</p>
<p> 
5. Drepturile dumeavoastră vis-a-vis de prelucrarea datelor cu caracter personal ?
</p>
<p> 
Aveţi dreptul de informare, dreptul de rectificare, ștergere sau restricţie a prelucrării datelor stocate, dreptul de a vă opune prelucrării datelor şi dreptul de a depune o plângere legală în conformitate cu prevederile legii privind protecţia datelor, în orice moment.
</p>
<p> 
Dreptul de informare:
</p>
<p> 
Aveţi dreptul să obţineți informaţii de la noi cu privire la faptul dacă şi în ce măsură prelucrăm datele dumneavoastră.
</p>
<p> 
Dreptul de rectificare:
</p>
<p> 
În cazul în care prelucrăm date care sunt incomplete sau incorecte, aveți dreptul de a obține rectificarea sau completarea acestor date de la noi în orice moment.
</p>
<p> 
Dreptul de ştergere:
</p>
<p> 
Aveţi dreptul să obţineți de la noi ştergerea datelor în cazul în care am prelucrat ilegal aceste date sau în cazul în care prelucrarea acestor date constituie o încălcare disproporționată a intereselor dumneavoastră legitime. Vă rugăm să reţineţi că ştergerea imediată poate fi interzisă din anumite motive, de ex. dispoziții legale privind reținerea datelor.
</p>
<p> 
Dreptul la limitarea prelucrării:
</p>
<p> 
Aveţi dreptul de a obţine limitarea prelucrării datelor dumneavoastră în cazul în care contestați exactitatea datelor, pentru o perioadă care ne permite să verificăm acuratețea datelor,
</p>
<p> 
Dreptul de a obiecta:
</p>
<p> 
În cazul în care prelucrăm datele pe baza intereselor legitime, aveţi dreptul de a obiecta la această prelucrare, din motive legate de situaţia dumneavoastră specială, în orice moment. Nu vom mai prelucra datele decât atunci când putem demonstra motive convingătoare și legitime pentru prelucrare care vă depășesc interesele, drepturile și libertățile sau pentru stabilirea, exercitarea sau apărarea cererilor legale. Aveţi dreptul de a vă opune prelucrării datelor dumneavoastră în scop de marketing direct în orice moment, fără precizarea unui motiv.
</p>
<p> 
Dreptul la reclamaţie:
</p>
<p> 
Dacă credeţi că vă prelucrăm datele prin încălcarea legii privind protecţia datelor din Romania sau legislatia europeană, vă rugăm să ne contactaţi prin e-mail la adresa {company.company.CONFC_Email}
</p>
<p> 
În caz de dubii, putem avea nevoie de informaţii suplimentare pentru confirmarea identității dumneavoastră. Aceasta serveşte la protejarea drepturilor şi a confidenţialității datelor dumneavoastră.
</p>
<p> 
Aveţi dreptul de a depune o plângere la Autoritatea Națională de Supraveghere a Prelucrării Datelor cu Caracter Personal (ANSPDCP), B-dul G-ral. Gheorghe Magheru 28-30, Sector 1, 010336 Bucuresti, Tel. 0318.059.211, Email: anspdcp@dataprotection.ro
</p>
        </div>
        {/* End row */}
      </div>
      {/* End container */}
    </div>
  );
};

export default Gdpr;
