import React from 'react';
import { useState } from "react";
import Header from "@cloudscape-design/components/header";
import Container from "@cloudscape-design/components/container";
import SpaceBetween from "@cloudscape-design/components/space-between";
import AppLayout from "@cloudscape-design/components/app-layout";
import SideNavigation from "./Sidenavigation";
import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";
import TextContent from "@cloudscape-design/components/text-content";
import Grid from "@cloudscape-design/components/grid";
import ColumnLayout from "@cloudscape-design/components/column-layout";
import TopNavigation from "./TopNavigation"
import Button from "@cloudscape-design/components/button";

export default function Lab(props) {
    const [value, setValue] = useState("");

    let content = {
        "en":<SpaceBetween size="m">
        <Container>
            <TextContent>
                <h1>Our vision</h1>
                <hr></hr>
                <p>We aim to streamline the way medical analysis laboratories operate. </p>
                <p>By using the latest technologies, we want to put the patient in the middle of a platform that actively watches over his health.</p>
                <p>Cloud computing will facilitate this platform to be available anywhere and anytime, on any desktop or mobile device. </p>
                <p>IOT will securely transport data from monitoring devices or medical analyzers to the platform. With the help of machine learning, we will analyze the quality of the information collected, and we will make decisions in real time. </p>
                <p>Technologies like data warehouse and Big Data will help us analyze a large volume of data in a very short time. </p>
                <p>Data that is not in electronic format will be digitized automatically using OCR automatic recognition technologies. </p>
                <p>The mobile app will collect data from smartphone or smartwatch sensors to correlate with data from the platform, electronic channels will maintain active communication with the patient.</p>
                <br></br>
                <ColumnLayout columns={1}>
                    <div><img
                        className="img-fluid"
                        src="images/lab/ro/hero-one.webp"
                        alt="info-image"
                        width="100%"
                    /></div>
                </ColumnLayout>
            </TextContent>
        </Container>
        <Container>
            <TextContent>
                <h1>Flexible configuration</h1>
                <hr></hr>
                <p>Simple to configure and use.</p>
                <br></br>
                <ColumnLayout columns={1}>
                    <div><img
                        className="img-fluid"
                        src="images/lab/ro/hero-two.webp"
                        alt="info-image"
                        width="100%"
                    /></div>
                </ColumnLayout>
            </TextContent>
        </Container>
        <Container>
            <TextContent>
                <h1>Efficient laboratory management</h1>
                <hr></hr>
                <p>You have real-time access to complex data to help you make the right decision by using the Business Intelligence (BI) tool</p>
                <p>Complex reports</p>
                <br></br>
                <ColumnLayout columns={1}>
                    <div><img
                        className="img-fluid"
                        src="images/lab/ro/hero-three.webp"
                        alt="info-image"
                        width="100%"
                    /></div>
                </ColumnLayout>
            </TextContent>
        </Container>
        <Container>
            <TextContent>
                <h1>Accessible anytime and anywhere Directly from the browser</h1>
                <hr></hr>
                <p>The medical information can be accessed by the laboratory staff from a browser installed on any device connected to the Internet</p>
                <p>No installation and configuration. The maintenance of the platform is done remotely, without the need for preconfigured computers and special computing techniques</p>
                <p>Secure access - Connecting users through 2-step authentication with user, password and MFA</p>
                <br></br>
                <ColumnLayout columns={1}>
                    <div><img
                        className="img-fluid"
                        src="images/lab/ro/tab-feature-three.webp"
                        alt="info-image"
                        width="100%"
                    /></div>
                </ColumnLayout>
            </TextContent>
        </Container>
        <Container>
            <TextContent>
                <h1>The platform according to the ISO 15189 standard</h1>
                <hr></hr>
                <p>The Lotus Lab platform addresses all the specifications of the RENAR ISO 15189 accreditation requirements.</p>
                <p>We place special emphasis on the traceability of results. </p>
                <p>The platform has implemented a security system based on access levels. In this way it can be determined according to the user which functions he can access within the system. Access to the system is based on user and password plus MFA (Multi-factor authentication)</p>
            </TextContent>
        </Container>
        <Container>
            <TextContent>
                <h1> WebSite - Created automatically based on the data entered in the platform</h1>
                <hr></hr>
                <p>Companies usually create a website separate from the medical management application.</p>
                <p>For this reason, many information will not be updated on time, or certain events on the website will not reach decision-makers in a timely manner</p>
                <p>The information from the Privacy Policy, Evaluation Form, Contact are automatically sent to the platform and you will receive notifications</p>
                <p>The patient can directly download the analysis bulletins based on personal code and online access code from the WebSite</p>
                <br></br>
                <ColumnLayout columns={1}>
                    <div><img
                        className="img-fluid"
                        src="images/lab/ro/WebSite.png"
                        alt="info-image"
                        width="100%"
                    /></div>
                </ColumnLayout>
            </TextContent>
        </Container>
        <Container>
            <TextContent>
                <h1>Android and Apple mobile app</h1>
                <hr></hr>
                <p>The patient has access through the application to the analysis reports that he has carried out in the laboratory, he can download the analysis reports and share them automatically, for example with the medical staff</p>
                <ColumnLayout columns={1}>
                    <div><img
                        className="img-fluid"
                        src="images/lab/ro/AppLab.png"
                        alt="info-image"
                        width="100%"
                    /></div>
                </ColumnLayout>
            </TextContent>
        </Container>
        <Container>
            <TextContent>
                <h1>Quickly import and complete analyses</h1>
                <hr></hr>
                <p>You have quick access to the patient's historical data</p>
                <ColumnLayout columns={1}>
                    <div><img
                        className="img-fluid"
                        src="images/lab/ro/History.png"
                        alt="info-image"
                        width="100%"
                    /></div>
                </ColumnLayout>
            </TextContent>
        </Container>
        <Container>
            <TextContent>
                <h1>CNAS reporting - SIUI communications</h1>
                <hr></hr>
                <p>Lotus Lab automatically exports in the SIUI format required by CAS or OPSNAJ based on the data entered in the analysis report, having implemented all the rules for validating tickets related to CAS or OPSNAJ.</p>
                <p>The system allows online reporting based on digital signature, electronic invoice and statement.</p>
            </TextContent>
        </Container>
        <Container>
            <TextContent>
                <h1>Communication center</h1>
                <hr></hr>
                <p>The Lotus Lab platform was designed with the idea of ​​electronic communication with the client. The platform can automatically send emails and monitor whether they reach their destination.</p>
                <p>The platform can send SMS messages.</p>
                <p>LotusApp mobile application - with the help of the mobile application available in Google Play and the Apple Store, patients can have access to the investigation history, can download the analysis reports and can automatically share them, for example, with the medical staff.</p>
                <p>Online access - directly from the laboratory website.</p>
            </TextContent>
        </Container>
        <Container>
            <TextContent>
                <h1>AWS Cloud</h1>
                <hr></hr>
                <p>Your data is safe in multi-billion dollar data centers. They cost significant amounts because they are specially built to ensure over 99.99% availability of all systems. The data is secured and encrypted. These data are automatically backed up daily. The databases are specially designed to operate with 99.99% availability, always having 6 copies of the data in 3 areas of the region. The architecture based on microservices ensures that the entire system responds to the requirements, allocating additional resources when needed. The platform carefully monitors that all services work correctly, having the ability to automatically heal the system and restart certain services in case of failure.</p>
            </TextContent>
        </Container>
        <Container>
            <TextContent>
                <h1>Quick connect for medical analyzers</h1>
                <hr></hr>
                <p>It is possible to configure complex analysis structures. Two-way communications with laboratory analyzers.</p>
                <p>Medical analyzers and other patient monitoring devices can be connected to the Lotus Lab platform from virtually any location with maximum security</p>
            </TextContent>
        </Container>

    </SpaceBetween>,
    "ro":
    <SpaceBetween size="m">
                    <Container>
                        <TextContent>
                            <h1>Viziunea Lotus Lab</h1>
                            <hr></hr>
                            <p>Ne propunem sa eficientizam modul în care operează laboratoarele de analize medicale. </p>
                            <p>Prin folosirea ultimelor tehnologii, dorim sa punem pacientul în mijlocul unui platforme care veghează activ la sanatatea acestuia.</p>
                            <p>Cloud computing va facilita ca aceasta platforma să fie disponibilă oriunde și oricând, pe orice device desktop sau mobile. </p>
                            <p>IOT va transporta în siguranță datele din dispozitivele de monitorizare sau analizoarele medicale către platforma. Cu ajutorul machine learning vom analiza calitatea informațiilor colectate, și vom lua decizii în timp real. </p>
                            <p>Tehnologii ca și data warehouse și Big Data ne vor ajuta sa analizam un volum mare de date într-un timp foarte scurt. </p>
                            <p>Datele care nu se afla în format electronic vor fi digitalizate automat folosind OCR tehnologii de recunoaștere automată. </p>
                            <p>Aplicația mobilă va colecta date de la senzorii smartphone-ului sau a smartwatch-ului pentru a le corela cu datele din platforma, canalele electronice vor menține o comunicare activa cu pacientul.</p>
                            <br></br>
                            <ColumnLayout columns={1}>
                                <div><img
                                    className="img-fluid"
                                    src="images/lab/ro/hero-one.webp"
                                    alt="info-image"
                                    width="100%"
                                /></div>
                            </ColumnLayout>
                        </TextContent>
                    </Container>
                    <Container>
                        <TextContent>
                            <h1>Configurare flexibila</h1>
                            <hr></hr>
                            <p>Simplu de configurat si de folosit.</p>
                            <br></br>
                            <ColumnLayout columns={1}>
                                <div><img
                                    className="img-fluid"
                                    src="images/lab/ro/hero-two.webp"
                                    alt="info-image"
                                    width="100%"
                                /></div>
                            </ColumnLayout>
                        </TextContent>
                    </Container>
                    <Container>
                        <TextContent>
                            <h1>Management eficient laborator</h1>
                            <hr></hr>
                            <p>Aveti access in timp real la date complexe pentru a va ajuta sa decideti corect prin folosirea tool-ul pentru Business Intelligence (BI)</p>
                            <p>Rapoarte complexe</p>
                            <br></br>
                            <ColumnLayout columns={1}>
                                <div><img
                                    className="img-fluid"
                                    src="images/lab/ro/hero-three.webp"
                                    alt="info-image"
                                    width="100%"
                                /></div>
                            </ColumnLayout>
                        </TextContent>
                    </Container>
                    <Container>
                        <TextContent>
                            <h1>Accesibil oricand si oriunde Direct din browser</h1>
                            <hr></hr>
                            <p>Informatia medicala poate fi accesata de catre personalul laboratorului dintr-un browser instalat pe orice dispozitiv conectat la internet</p>
                            <p>Fara instalare si configurare. Mentenanta platformei se face de la distanta, fara a fi nevoie de calculatoare preconfigurate su tehnica de calcul speciala</p>
                            <p>Acces securizat - Conectarea utilizatorilor prin autentificare in 2 pasi cu user, parola si MFA</p>
                            <br></br>
                            <ColumnLayout columns={1}>
                                <div><img
                                    className="img-fluid"
                                    src="images/lab/ro/tab-feature-three.webp"
                                    alt="info-image"
                                    width="100%"
                                /></div>
                            </ColumnLayout>
                        </TextContent>
                    </Container>
                    <Container>
                        <TextContent>
                            <h1>Platforma conform standard ISO 15189</h1>
                            <hr></hr>
                            <p>Platforma Lotus Lab adresează toate specificatiile cerințelor de acreditare RENAR ISO 15189.</p>
                            <p>Punem un accent deosebit pe trasabilitatea rezultatelor. </p>
                            <p>Plaforma are implementat un sistem de securitate bazat pe niveluri de access. Astfel se poate stabili în funcție de utilizator ce funcții poate accesa în cadrul sistemului. Accesul în sistem se face pe bază de utilizator si parolă plus MFA ( Multi-factor authentication)</p>
                        </TextContent>
                    </Container>
                    <Container>
                        <TextContent>
                            <h1> WebSite - Creat automat pe baza datelor introduse in platforma</h1>
                            <hr></hr>
                            <p>De obicei companiile își creaza un site separat de aplicatia de gestiune medicala.</p>
                            <p>Din acest motiv multe informații nu vor fi actualizate la timp, sau anumite evenimente din website nu vor ajunge în timp util la persoanele decidente</p>
                            <p>Informațiile din Politică de Confidentialitate, Formular de evaluare, Contact sunt transmise automat in platforma si vei primi notificari</p>
                            <p>Pacientul poate descarca direct din WebSite buletinele de analize pe baza de cnp si cod access online</p>
                            <br></br>
                            <ColumnLayout columns={1}>
                                <div><img
                                    className="img-fluid"
                                    src="images/lab/ro/WebSite.png"
                                    alt="info-image"
                                    width="100%"
                                /></div>
                            </ColumnLayout>
                        </TextContent>
                    </Container>
                    <Container>
                        <TextContent>
                            <h1>Aplicație mobilă Android și Apple</h1>
                            <hr></hr>
                            <p>Pacientul are acces prin aplicație la buletinele de analize pe care le-a efectuat in laborator, poate descarca buletinele de analize și le pot partaja automat de exemplu cu personalul medical</p>
                            <ColumnLayout columns={1}>
                                <div><img
                                    className="img-fluid"
                                    src="images/lab/ro/AppLab.png"
                                    alt="info-image"
                                    width="100%"
                                /></div>
                            </ColumnLayout>
                        </TextContent>
                    </Container>
                    <Container>
                        <TextContent>
                            <h1>Importa si completeaza rapid analizele</h1>
                            <hr></hr>
                            <p>Ai access rapid la datele istorice ale pacientului</p>
                            <ColumnLayout columns={1}>
                                <div><img
                                    className="img-fluid"
                                    src="images/lab/ro/History.png"
                                    alt="info-image"
                                    width="100%"
                                /></div>
                            </ColumnLayout>
                        </TextContent>
                    </Container>
                    <Container>
                        <TextContent>
                            <h1>Raportare CNAS -Comunicatii SIUI</h1>
                            <hr></hr>
                            <p>Lotus Lab exportă automat în formatul SIUI cerut de CAS sau OPSNAJ pe baza datelor introduce în buletinul de analize, avand implementate toate regulile de validare ale biletelor aferente CAS, sau OPSNAJ.</p>
                            <p>Sistemul permite raportarea online pe bază de semnătură digitală, factură electronică și decont.</p>
                        </TextContent>
                    </Container>
                    <Container>
                        <TextContent>
                            <h1>Centrul de comunicatii</h1>
                            <hr></hr>
                            <p>Platforma Lotus Lab a fost gandita cu idea de comunicație electronică cu clientul.Platforma poate trimite automat email și poate monitoriza dacă acestea ajung la destinatie.</p>
                            <p>Platforma poate trimite mesaje SMS.</p>
                            <p>Aplicație mobilă LotusApp - cu ajutorul aplicației mobile disponibilă în Google Play si Apple Store pacienții poate avea acces la istoricul investigațiilor, pot downloada buletinele de analize și le pot partaja automat de exemplu cu personalul medical.</p>
                            <p>Access online - direct din site-ul laboratorului.</p>
                        </TextContent>
                    </Container>
                    <Container>
                        <TextContent>
                            <h1>Cloud AWS</h1>
                            <hr></hr>
                            <p>Datele D-voastra sunt în siguranța în centre de date care valorează miliarde de dolari. Costa sume importante pentru ca sunt construite special să asigure o disponibilitate de peste 99.99% a tuturor sistemelor. Datele sunt securizate si criptate. Pentru aceste date se face backup zilnic automat. Bazele de date sunt special concepute sa functioneze cu disponibilitate de 99.99% avand tot timpul 6 copii ale datelor în 3 zonele ale regiunii. Arhitectura bazată pe microservicii asigura că întregul sistem sa raspunda cerintelor, alocând resurse suplimentare atunci cand este nevoie. Platforma monitorizează atent ca toate servicii sa funcționeze corect, avand capacitati de a se vindeca automat sistemul si a reporni anumite servicii în caz de avarie.</p>
                        </TextContent>
                    </Container>
                    <Container>
                        <TextContent>
                            <h1>Conectare rapida pentru analizoare medicale</h1>
                            <hr></hr>
                            <p>Exista posibilitatea de a configura structuri complexe de analize. Comunicații bidirecționale cu analizoarele laboratorului.</p>
                            <p>Analizoarele medicale și alte dispozitive de monitorizare pentru pacient pot fi conectate la platforma Lotus Lab practic din orice locație în condiții de maximă securitate</p>
                        </TextContent>
                    </Container>

                </SpaceBetween>
    }


    return (
        <div>
        <TopNavigation language={props.language} setLanguage={props.setLanguage} region={props.region}/>
        <AppLayout
            breadcrumbs={<BreadcrumbGroup
                items={[
                    { text: "Lotus Code", href: window.lotus },
                    {
                        text: "Lotus Lab",
                        href: "/lotuslab"
                    }
                ]}
                ariaLabel="Breadcrumbs"
            />}
            contentHeader={
                <Grid
                    gridDefinition={[{ colspan: 8 }, { colspan: 6 }]}
                >
                    <div><Header
                        variant="h1"
                        description={props.setLanguage=="ro"? "Platformă in cloud destinată laboratoarelor de analize medicale":"Cloud platform for medical analysis laboratories"}
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                              { props.language=="ro" &&
                              <Button href={"https://lab.lotuscode.eu/createaccount"}>Creaza un cont in platforma</Button>                           
                              }
                            </SpaceBetween>
                          }
                    >
                        Lotus Lab
                    </Header>
                    </div>
                    <div></div>
                </Grid>}
            navigation={<SideNavigation language={props.language}  setLanguage={props.setLanguage}></SideNavigation>}
            toolsHide="false"
            content={content[props.language]}>
        </AppLayout>
        </div>
    );
}