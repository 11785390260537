import { Button} from 'react-bootstrap';
import React from 'react';
import parse from 'html-react-parser';

const Footer = (company) => {
  return (
    <footer className="wide-40 footer division">
      <div className="container">
        {/* FOOTER CONTENT */}
        <div className="row">
          {/* FOOTER INFO */}
          <div className="col-md-6 col-lg-3">
            <div className="footer-info mb-40">
              {/* Footer Logo */}
              {/* For Retina Ready displays take a image with double the amount of pixels that your image will be displayed (e.g 360 x 80  pixels) */}
              <h5 className="h5-xs">Servicii</h5>
              {/* Text */}
                {parse(company.company.CONFC_AboutServices)}
              {/* Social Icons */}
              <div className="footer-socials-Buttons mt-20">
                <ul className="foo-socials text-center clearfix">
                  <li>
                    <a href={company.company.CONFC_FacebookAccount} className="ico-facebook">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>               
                </ul>
              </div>
            </div>
          </div>
          {/* FOOTER CONTACTS */}
          <div className="col-md-6 col-lg-3">
            <div className="footer-box mb-40">
              {/* Title */}
              <h5 className="h5-xs">Adresa</h5>
              {/* Address */}
              <p>{company.company.CONFC_Address}</p>
              {/* Email */}
              <p className="foo-email mt-20">
                Email:
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    href={"mailto:"+company.company.CONFC_Email}
                  >
                   {company.company.CONFC_Email}
                  </a>
              </p>
              {/* Phone */}
              <p>Telefon: +{company.company.CONFC_Phone}</p>
            </div>
          </div>
          {/* FOOTER WORKING HOURS */}
          <div className="col-md-6 col-lg-3">
            <div className="footer-box mb-40">
              {/* Title */}
              <h5 className="h5-xs">Program</h5>
              {/* Working Hours */}
              {parse(company.company.CONFC_Schedule)}
            </div>
          </div>
          {/* FOOTER PHONE NUMBER */}
          <div className="col-md-6 col-lg-3">
            <div className="footer-box mb-40">
              {/* Title */}
              <h5 className="h5-xs">Telefon</h5>
              {/* Footer List */}
              <h5 className="h5-xl blue-color">{company.company.CONFC_Phone}</h5>
              {/* Text */}
             
            </div>
          </div>
        </div>
        {/* END FOOTER CONTENT */}
        {/* FOOTER COPYRIGHT */}
        <div className="bottom-footer">
          <div className="row">
            <div className="col-md-12">
              <p className="footer-copyright">
                © {new Date().getFullYear()} <span>Lotus Code</span>. All Rights
                Reserved - V:0.45   <Button variant="link"  className="btn btn-blue" href={window.lotus+"/Policy"}>
                Politica de Confidentialitate
                            </Button>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* End container */}
    </footer>
  );
};

export default Footer;
