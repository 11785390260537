import React from "react";

const Radiology = (company) => {
  return (
    <div id="single-blog-page" className="wide-100 blog-page-section division">
      <div className="container">
        <div className="row">
          <div className="post-inner-img">
            <h4 className="h4-lg steelblue-color">
              Radiologie
            </h4>
          </div>

          <p>
          Clinica noastră este dotată cu aparatură de ultimă oră în domeniul radiologiei. Zona în care sunt executate radiografiile este realizată astfel încât să existe o protecţie maximă a pacienţilor.

Radiografiile dentare reprezintă un ajutor important pentru medic. Un diagnostic complet şi corect se poate pune doar după vizualizarea radiografiilor. Pe radiografii se poate observa prezenţa cariilor, corectitudinea tratamentelor, acestea fiind necesare pentru realizarea implanturilor dentare  sau ca evaluare anuală a stării de sănătate dentară a pacienţilor.
          </p>   
          
        </div>
        {/* End row */}
      </div>
      {/* End container */}
    </div>
  );
};

export default Radiology;
