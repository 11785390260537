import { Button } from 'react-bootstrap';
import React from 'react';
import { useState } from "react";
import ReCAPTCHA from 'react-google-recaptcha';

const MobileMenu = (props) => {

  const [activeMenu, setActiveMenu] = useState("");
  const activeMenuSet = (value) =>
    setActiveMenu(activeMenu === value ? "" : value),
    activeLi = (value) =>
      value === activeMenu ? { display: "block" } : { display: "none" },
    activeArrow = (value) => (value === activeMenu ? "ws-activearrow" : "");
  return (
    <div
      className="wsmainfull menu clearfix cloned d-block d-lg-none"
      style={{
        position: "fixed",
        top: 0,
        marginTop: 0,
        zIndex: 500,
        display: "block",
        left: 0,
        width: 731       
      }}
    >
      <div className="wsmainwp clearfix">
        {/* LOGO IMAGE */}
        {/* For Retina Ready displays take a image with double the amount of pixels that your image will be displayed (e.g 360 x 80 pixels) */}
        <div className="desktoplogo">
          <a href={window.lotus + "/"}>
            <img
              src="images/logo.png"
              width={180}
              height={40}
              alt="header-logo"
            />
          </a>
        </div>
        {/* MAIN MENU */}
        <nav className="wsmenu clearfix" >
          <div className="overlapblackbg" />
          <ul className="wsmenu-list">
            <li className="nl-simple" aria-haspopup="true">
              <a href={window.lotus + "/"}>Acasa</a>
            </li>
            {/* NAVIGATION MENU BUTTON */}
            {props.company.CONFC_OnlineAppointment==1 &&
            <li className="nl-simple header-btn" aria-haspopup="true">
              <Button variant="link" href={window.lotus + "/Appointment"}>
                Programare online
              </Button>
            </li>
            }
            <li className="nl-simple" aria-haspopup="true">
              <a href={"tel:" + props.company.CONFC_Phone}>Suna acum</a>
            </li>
            <li className="nl-simple" aria-haspopup="true">
              <a href={window.lotus + "/Contact"}>Contact</a>
            </li>
            {(props.company.type == "med" && props.company.modul == "Stoma") &&
              <React.Fragment>
                <li aria-haspopup="true">
                  <Button variant="link" href={window.lotus + "/Consultations"}>
                    Consultatii
                  </Button>
                </li>
                <li aria-haspopup="true">
                  <Button variant="link" href={window.lotus + "/Aesthetics"}>
                    Estetica dentara
                  </Button>
                </li>
                <li aria-haspopup="true">
                  <Button variant="link" href={window.lotus + "/Pediatricdentistry"}>
                    Stomatologie pediatrica
                  </Button>
                </li>
                <li aria-haspopup="true">
                  <Button variant="link" href={window.lotus + "/Orthodontics"}>
                    Ortodontie
                  </Button>
                </li>

                <li aria-haspopup="true">
                  <Button variant="link" href={window.lotus + "/Implant"}>
                    Implantologie
                  </Button>
                </li>
                <li aria-haspopup="true">
                  <Button variant="link" href={window.lotus + "/Radiology"}>
                    Radiologie
                  </Button>
                </li>
              </React.Fragment>
            }
            {props.config.module.find(x => x.code == "RequestLab") &&
              <React.Fragment>
              <li className="nl-simple" aria-haspopup="true">
                <a href={window.lotus + "/OnlineAccessLab"}>Rezultate analize medicale</a>
              </li>
              { (props.company.CONFC_HomeHarvest == 1) || (props.company.CONFC_HomeHarvest == null) &&
              <li className="nl-simple" aria-haspopup="true">
                <a href={window.lotus + "/RequestHarvestLab"}>Recoltare probe la domiciliu</a>
              </li>
              }
              <li className="nl-simple" aria-haspopup="true">
                <a href={window.lotus + "/HowTo"}>Recoltare analize</a>
              </li>                         
              </React.Fragment>   
            }
            <li className="nl-simple" aria-haspopup="true">
              <a href={window.lotus + "/Policy"}>Politica de Confidentialitate</a>
            </li>
            <li className="nl-simple" aria-haspopup="true">
              <a href={window.lotus + "/Evaluation"}>Formular de evaluare</a>
            </li>
          </ul>
        </nav>{" "}

        {/* END MAIN MENU */}
      </div>
    </div>
  );
};

export default MobileMenu;
