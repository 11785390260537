import * as React from "react";
var Howto = function () {
    return (React.createElement("section", { id: "terms-page", className: "wide-80 terms-section division" },
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-lg-12" },
                    React.createElement("div", { className: "terms-holder pr-30" },
                        React.createElement("div", { className: "terms-box" },
                            React.createElement("div", { className: "terms-title" },
                                React.createElement("h4", { className: "h4-lg steelblue-color" }, "Recoltare Analize"),
                                React.createElement("br", null),
                                React.createElement("p", null, "Informatii generale pentru recoltarea cat mai corecta a probelor biologice pentru analiza"))),
                        React.createElement("div", { className: "terms-box" },
                            React.createElement("h5", { className: "h5-xl steelblue-color" }, "1. Proba de sange"),
                            React.createElement("ul", { className: "terms-list" },
                                React.createElement("li", null, "Majoritatea analizelor din sange necesita un post de 10-12 ore inainte de prelevarea analizei, recomandandu-se doar consumul de apa."),
                                React.createElement("li", null, "Unele teste poate obliga la intreruperea unor tratamente medicamentoase, pentru aceasta va fi necesara consultarea prealabila a medicului dvs."))),
                        React.createElement("div", { className: "terms-box" },
                            React.createElement("h5", { className: "h5-xl steelblue-color" }, "2. Proba de urina"),
                            React.createElement("p", null, "Pentru o recoltare corecta se efectueaza mai intai o igiena locala adecvata."),
                            React.createElement("p", null, "Pentru o analiza de rutina se utilizeaza prima urina de dimineata, care fiind foarte concentrata va permite detectarea unor substante aflate altfel in concentratii scazute."),
                            React.createElement("p", null, "Probele din mijlocul jetului urinar sunt utilizate in general pentru testele bacteriologice (urocultura) si citologice. Pentru aceasta dupa igiena locala se incepe sa urineze in vasul de toaleta, iar la scurt timp sa colecteze urina in recipientul steril pus la dispozitie de catre laborator.")),
                        React.createElement("div", { className: "terms-box" },
                            React.createElement("h5", { className: "h5-xl steelblue-color" }, "3. Proba de scaun"),
                            React.createElement("ul", { className: "terms-list" },
                                React.createElement("li", null, "pentru examenul coproparazitologic si coprocultura se va recolta intr-un recipient special achizitionat de la laborator o cantitate de scaun de marimea unui bob de mazare. Recoltarea se va face in functie de tranzitul intestinal, in cazul in care proba nu a fost recoltata in dimineata zilei in care proba va fi supusa analizei, aceasta necesita pastrare la o temperatura intre 2-8\u00B0C (la frigider) pana a 2 zi cand va fi preluata de catre laborator.")))))))));
};
export default Howto;
