import React from 'react';
import * as request from 'superagent';
import { API_SERVICEENDPOINT,API_SERVICEENDPOINT_LAB } from '../constants/endpoints';
import TagManager from 'react-gtm-module'
import AppLab from './AppLab'
import AppMed from './AppMed'
import AppLotus from './AppLotus'
import AppGSM from './AppGSM'


class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      company:[],
      config:null      
    };
  }

  componentDidMount() {
    let self=this;
    
    request          
    .post(API_SERVICEENDPOINT + '/api/online/Start')    
    .set('Content-Type', 'application/json;charset=UTF-8')  
    .set('Access-Control-Allow-Origin', '*')
    .end(function(err, res){
      if(res!=null)
      {           
          let obj=JSON.parse(res.text); 
          self.setState({config:obj},function(){
          
          let where=API_SERVICEENDPOINT;

          if(obj.client[0].type=="lab")
            where=API_SERVICEENDPOINT_LAB;

          if((obj.client[0].type=="lab")||(obj.client[0].type=="med"))                    
          request          
            .post(where + '/api/online/Company')    
            .set('Content-Type', 'application/json;charset=UTF-8')  
            .set('Access-Control-Allow-Origin', '*')
            .end(function(err, res){
              if(res!=null)
              {           
                  let obj=JSON.parse(res.text); 
                  self.setState({company:obj},function(){
                    self.setState({loading:false})
                    const tagManagerArgs = {
                      gtmId: this.state.company.CONFC_GoogleAnalitics
                    }           
                    TagManager.initialize(tagManagerArgs)
                  });        
              }
            });     
          else
          self.setState({loading:false})

          });                
      }
    });         
  }

  

  render() {
     if(this.state.loading)
    return (
      <div id="loader-wrapper">
        <div id="loader">
          <div className="loader-inner"></div>
        </div>
      </div>
    );

    if(this.state.config.client[0].type=="med")
    return(
      <AppMed company={this.state.company} config={this.state.config}></AppMed>
    )

    if(this.state.config.client[0].type=="lab")
    return(
      <AppLab company={this.state.company} config={this.state.config}></AppLab>
    )

    if(this.state.config.client[0].type=="lotus")
    return(
      <AppLotus></AppLotus>
    )

    if(this.state.config.client[0].type=="gsm")
    return(
      <AppGSM></AppGSM>
    )

    return(<div>Nothing</div>)
  }

}

export default App;
