import React from "react";
import {Button } from "react-bootstrap";
import parse from 'html-react-parser';

const DoctorDetails = (props) => {

  let doctor={};
  let category="";
  props.company.categories.forEach(categorie => {
    categorie.items.forEach(element => {
      if(element.id==props.value)
        {
          category=categorie.Name;
          doctor=element;
        }
    });
    
  });
  
  console.log(doctor);

  return (
    <section
      id="doctor-2-details"
      className="wide-70 doctor-details-section division"
    >
      <div className="container">
        <div className="row">
          {/* DOCTOR PHOTO */}
          <div className="col-md-5 col-xl-5">
            <div className="doctor-photo mb-30 text-center">
              {/* Photo */}
              
              { doctor.image!=null  &&
              <img
                className="img-fluid"
                src={doctor.image}
                alt="doctor-foto"
              />
              }
               { doctor.image==null  &&
              <img
                className="img-fluid"
                src="../images/medic_jd.jpg"
                alt="doctor-foto"
              />
              }

              {/* Doctor Contacts */}
              <div className="doctor-contacts">
                
                
              </div>
              {/* Button */}
              {props.company.CONFC_OnlineAppointment==1 &&
              <Button href={window.lotus+"/Appointment"}>
                  Programare Online
              </Button>
              }
              {/* Button */}
            </div>
          </div>
          {/* END DOCTOR PHOTO */}
          {/* DOCTOR'S BIO */}
          <div className="col-md-6 col-xl-6 offset-xl-1">
            <div className="doctor-bio">
              {/* Name */}
              <h2 className="h2-sm blue-color">{ doctor.Name}</h2>
              <h5 className="h5-lg blue-color">
               {category}
              </h5>
              {/* Text */}
              {doctor.description!=null &&
                  <p>
                  {parse(doctor.description)}
                  </p>
              }
              <div className="box-list">
                        {doctor.items.map((i)=>{
                          return(
                            <div>
                            <div className="box-list-icon blue-color">
                            <i className="fas fa-angle-double-right" />
                           </div>
                            <p>{i.Name}</p>
                         
                            </div>
                            )
                          })
                        }
                        {props.company.CONFC_OnlineAppointment==1 &&
                        <Button variant="link"  href={window.lotus+"/Appointment"}>
                         Programare online
                        </Button>                                                
                        }
                </div>                

            
              
            </div>
          </div>
          {/* END DOCTOR BIO */}
        </div>
        {/* End row */}
      </div>
      {/* End container */}
    </section>
  );
};

export default DoctorDetails;
