import React from 'react';
import { useState } from "react";
import Header from "@cloudscape-design/components/header";
import Container from "@cloudscape-design/components/container";
import SpaceBetween from "@cloudscape-design/components/space-between";
import AppLayout from "@cloudscape-design/components/app-layout";
import SideNavigation from "./Sidenavigation";
import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";
import TextContent from "@cloudscape-design/components/text-content";
import Grid from "@cloudscape-design/components/grid";
import ColumnLayout from "@cloudscape-design/components/column-layout";
import TopNavigation from "./TopNavigation"
import Button from "@cloudscape-design/components/button";

export default function Lab(props) {
    const [value, setValue] = useState("");

    let content = {
        "en":<SpaceBetween size="m">
        <Container>
            <TextContent>
                <h1>Efficient pharmacy management</h1>
                <hr></hr>
                  <p>Efficient management of medical prescriptions</p>
                  <p>Efficient management of fiscal receipts</p>
                <br></br>
                <ColumnLayout columns={1}>
                    <div><img
                        className="img-fluid"
                        src="images/rx24/ro/rx24_1.PNG"
                        alt="info-image"
                        width="100%"
                    /></div>
                </ColumnLayout>
            </TextContent>
        </Container>
        <Container>
            <TextContent>
                <h1>Flexible configuration</h1>
                <hr></hr>
                <p>Flexible configuration.</p>
                <br></br>
                <ColumnLayout columns={1}>
                    <div><img
                        className="img-fluid"
                        src="images/rx24/ro/rx24_2.PNG"
                        alt="info-image"
                        width="100%"
                    /></div>
                </ColumnLayout>
            </TextContent>
        </Container>                    
        <Container>
            <TextContent>
                <h1>CNAS reporting - SIUI Communications</h1>
                <hr></hr>
                <p>Lotus RX24 automatically exports to the SIUI format required by CAS or OPSNAJ</p>
                <p>The system allows online reporting based on digital signature, electronic invoice and statement.</p>
            </TextContent>
        </Container>
        <Container>
            <TextContent>
                <h1>Communications center</h1>
                <hr></hr>
                <p>The Lotus RX24 platform was designed with the idea of ​​electronic communication with the client. The platform can automatically send emails and monitor whether they reach their destination.</p>
                <p>The platform can send SMS messages.</p>
            </TextContent>
        </Container>
        <Container>
            <TextContent>
                <h1>Cloud AWS</h1>
                <hr></hr>
                <p>Your data is safe in multi-billion dollar data centers. They cost significant amounts because they are specially built to ensure over 99.99% availability of all systems. The data is secured and encrypted. These data are automatically backed up daily. The databases are specially designed to operate with 99.99% availability, always having 6 copies of the data in 3 areas of the region. The architecture based on microservices ensures that the entire system responds to the requirements, allocating additional resources when needed. The platform carefully monitors that all services work correctly, having the ability to automatically heal the system and restart certain services in case of failure.</p>
            </TextContent>
        </Container>            
    </SpaceBetween>,
    "ro":
    <SpaceBetween size="m">
                    <Container>
                        <TextContent>
                            <h1>Gestiunea eficienta a farmaciei</h1>
                            <hr></hr>
                              <p>Gestiunea eficienta a prescriptiilor medicale</p>
                              <p>Gestiunea eficienta a bonurilor fiscale</p>
                            <br></br>
                            <ColumnLayout columns={1}>
                                <div><img
                                    className="img-fluid"
                                    src="images/rx24/ro/rx24_1.PNG"
                                    alt="info-image"
                                    width="100%"
                                /></div>
                            </ColumnLayout>
                        </TextContent>
                    </Container>
                    <Container>
                        <TextContent>
                            <h1>Configurare flexibila</h1>
                            <hr></hr>
                            <p>Simple to configure and use</p>
                            <br></br>
                            <ColumnLayout columns={1}>
                                <div><img
                                    className="img-fluid"
                                    src="images/rx24/ro/rx24_2.PNG"
                                    alt="info-image"
                                    width="100%"
                                /></div>
                            </ColumnLayout>
                        </TextContent>
                    </Container>                    
                    <Container>
                        <TextContent>
                            <h1>Raportare CNAS - Comunicatii SIUI</h1>
                            <hr></hr>
                            <p>Lotus RX24 exportă automat în formatul SIUI cerut de CAS sau OPSNAJ</p>
                            <p>Sistemul permite raportarea online pe bază de semnătură digitală, factură electronică și decont.</p>
                        </TextContent>
                    </Container>
                    <Container>
                        <TextContent>
                            <h1>Centrul de comunicatii</h1>
                            <hr></hr>
                            <p>Platforma Lotus RX24 a fost gandita cu idea de comunicație electronică cu clientul. Platforma poate trimite automat email și poate monitoriza dacă acestea ajung la destinatie.</p>
                            <p>Platforma poate trimite mesaje SMS.</p>
                        </TextContent>
                    </Container>
                    <Container>
                        <TextContent>
                            <h1>Cloud AWS</h1>
                            <hr></hr>
                            <p>Datele D-voastra sunt în siguranța în centre de date care valorează miliarde de dolari. Costa sume importante pentru ca sunt construite special să asigure o disponibilitate de peste 99.99% a tuturor sistemelor. Datele sunt securizate si criptate. Pentru aceste date se face backup zilnic automat. Bazele de date sunt special concepute sa functioneze cu disponibilitate de 99.99% avand tot timpul 6 copii ale datelor în 3 zonele ale regiunii. Arhitectura bazată pe microservicii asigura că întregul sistem sa raspunda cerintelor, alocând resurse suplimentare atunci cand este nevoie. Platforma monitorizează atent ca toate servicii sa funcționeze corect, avand capacitati de a se vindeca automat sistemul si a reporni anumite servicii în caz de avarie.</p>
                        </TextContent>
                    </Container>            
                </SpaceBetween>
    }


    return (
        <div>
        <TopNavigation language={props.language} setLanguage={props.setLanguage} region={props.region}/>
        <AppLayout
            breadcrumbs={<BreadcrumbGroup
                items={[
                    { text: "Lotus Code", href: window.lotus },
                    {
                        text: "Lotus RX24",
                        href: "/lotusrx24"
                    }
                ]}
                ariaLabel="Breadcrumbs"
            />}
            contentHeader={
                <Grid
                    gridDefinition={[{ colspan: 8 }, { colspan: 6 }]}
                >
                    <div><Header
                        variant="h1"
                        description={props.setLanguage=="ro"? "Platformă in cloud destinată farmaciilor":"Cloud platform for pharmacies"}                      
                    >
                        Lotus RX24
                    </Header>
                    </div>
                    <div></div>
                </Grid>}
            navigation={<SideNavigation language={props.language}  setLanguage={props.setLanguage}></SideNavigation>}
            toolsHide="false"
            content={content[props.language]}>
        </AppLayout>
        </div>
    );
}