import React from "react";

const Consultations = (company) => {
  return (
    <div id="single-blog-page" className="wide-100 blog-page-section division">
      <div className="container">
        <div className="row">
          <div className="post-inner-img">
            <h4 className="h4-lg steelblue-color">
              Consultatii
            </h4>
          </div>

          <p>
          Consultaţia reprezintǎ primul pas în comunicarea dintre medic şi pacient iar de aceea, la {company.company.CONFC_Name} îi acordǎm o atenţie specialǎ. O consultaţie dentarǎ nu înseamnǎ privitul unui dinte, nu se rezumǎ la un dialog de multe ori comic de genul  "mǎ doare mǎseaua - deschideţi...da, aveţi o carie-cât costă-ok, plombaţi-o", cǎci aparatul oro-maxilo-facial este un ansamblu complex, ce trebuie analizat cu mare atenţie.Vom face tot ce ne stǎ în putinţǎ sǎ vǎ demonstrǎm că “frica de dentist” nu este justificată. Pe măsurǎ ce vă veţi cunoaşte medicul dentist şi vǎ veţi câştiga încrederea în el şi în ceilalţi membrii ai clinicii, veţi realiza cǎ temerile au dispǎrut, iar grija pentru sǎnǎtatea dumneavoastra dentarǎ va ajunge un obicei normal din viaţa fiecǎruia.
          </p>
          <p>
          Fişa individuală se înregistrează într-o bază de date, la care are acces numai personalul medical al clinicii, implicat în tratarea afecţiunilor stomatologice ale dumneavoastră. Personalul nostru respectă reguli stricte de confidenţialitate asupra tuturor informaţiilor (medicale sau nu) continute în fişa individuală a pacientului.

          </p>
          
        </div>
        {/* End row */}
      </div>
      {/* End container */}
    </div>
  );
};

export default Consultations;
