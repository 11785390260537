import React from "react";
import MobileMenu from "../MobileMenu";
import { Button } from 'react-bootstrap';

const Header = ({ toggleMenu, toggle, company, localhost, config }) => {
  return (
    <header id="header" className="header">
      {/* MOBILE HEADER */}
      <div className="wsmobileheader clearfix">
        <Button variant="link" href="#">
          <a
            id="wsnavtoggle"
            onClick={() => toggleMenu()}
            className="wsanimated-arrow"
          >
            <span />
          </a>
        </Button>
        <span className="sigla">
          <strong>
            {company.CONFC_Name}
          </strong>
        </span>

      </div>
      {/* HEADER STRIP */}
      <div className="headtoppart bg-blue clearfix">
        <div className="headerwp clearfix">
          {/* Address */}
          <div className="headertopleft">
            <div className="address clearfix">
              <span>
                <i className="fas fa-map-marker-alt" />
                {company.CONFC_Address}
              </span>
              <a
                onClick={(e) => {
                  e.preventDefault();
                }}
                href={"tel:" + company.CONFC_Phone}
                className="callusbtn"
              >
                <i className="fas fa-phone" />
                {company.CONFC_Phone}
              </a>
            </div>
          </div>
          {/* Social Buttons */}
          <div className="headertopright">
            <a className="facebookicon" title="Facebook" href={company.CONFC_FacebookAccount}>
              <i className="fab fa-facebook-f" />
              <span className="mobiletext02">Facebook</span>
            </a>
          </div>
        </div>
      </div>
      {/* END HEADER STRIP */}
      {/* NAVIGATION MENU */}
      <div className="wsmainfull menu clearfix d-none d-lg-block">
        <div className="wsmainwp clearfix">
          {/* LOGO IMAGE */}
          {/* For Retina Ready displays take a image with double the amount of pixels that your image will be displayed (e.g 360 x 80 pixels) */}
          <div className="desktoplogo">
            <a className="sigla" href={window.lotus + "/"}>
              <strong>
                {company.CONFC_Name}
              </strong>
            </a>
          </div>
          {/* MAIN MENU */}
          <nav className="wsmenu clearfix">
            <ul className="wsmenu-list">
              <li className="nl-simple" aria-haspopup="true">
                <a href={window.lotus + "/"}>Acasa</a>
              </li>
              {/* DROPDOWN MENU */}
              {(company.type=="med" && company.modul=="Stoma") &&
              <li aria-haspopup="true">
                <a href="#">
                  Servicii <span className="wsarrow" />
                </a>                
                <ul className="sub-menu">                  
                  <li aria-haspopup="true">
                    <Button variant="link" href={window.lotus + "/Consultations"}>
                      Consultatii
                    </Button>
                  </li>
                  <li aria-haspopup="true">
                    <Button variant="link" href={window.lotus + "/Aesthetics"}>
                      Estetica dentara
                    </Button>
                  </li>
                  <li aria-haspopup="true">
                    <Button variant="link" href={window.lotus + "/Pediatricdentistry"}>
                      Stomatologie pediatrica
                    </Button>
                  </li>
                  <li aria-haspopup="true">
                    <Button variant="link" href={window.lotus + "/Orthodontics"}>
                      Ortodontie
                    </Button>
                  </li>

                  <li aria-haspopup="true">
                    <Button variant="link" href={window.lotus + "/Implant"}>
                      Implantologie
                    </Button>
                  </li>
                  <li aria-haspopup="true">
                    <Button variant="link" href={window.lotus + "/Radiology"}>
                      Radiologie
                    </Button>
                  </li>
                </ul>
               
              </li>
              }
              {/* END DROPDOWN MENU */}
              <li className="nl-simple" aria-haspopup="true">
                <a href={window.lotus + "/Policy"}>Politica de Confidentialitate</a>
              </li>
              <li className="nl-simple" aria-haspopup="true">
                <a href={window.lotus + "/Evaluation"}>Formular de evaluare</a>
              </li>
              {/* NAVIGATION MENU BUTTON */}
              {(company.type == "med" && company.CONFC_OnlineAppointment==1) &&
                <li className="nl-simple header-btn" aria-haspopup="true">
                  <Button variant="danger" href={window.lotus + "/Appointment"}>
                    Programare online
                  </Button>
                </li>
              }
              <li className="nl-simple" aria-haspopup="true">
                <a href={window.lotus + "/Contact"}>Contact</a>
              </li>
            </ul>
          </nav>
          {/* END MAIN MENU */}
        </div>
      </div>
      <MobileMenu company={company} config={config} />

      {/* END NAVIGATION MENU */}
    </header>
  );
};

export default Header;
