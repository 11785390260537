import React from 'react';
import { useEffect, useState } from "react";
import { aTagClick, dataImage, stickyNav } from "../utils";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";

const Layouts = ({ children,company,config}) => {
  const [toggle, setToggle] = useState(false);
  useEffect(() => {
    dataImage();
    aTagClick();
    window.addEventListener("scroll", stickyNav);
  });
  useEffect(() => {
    document.querySelector("body").classList.remove("wsactive");
  }, []);
  const openClick = () => {
    setToggle(!toggle);
    document.querySelector("body").classList.toggle("wsactive");
  };

  return (
    <div id="page" className="page-wrapper demo">
      <Header  company={company} config={config} toggleMenu={() => openClick()} toggle={toggle} />
      {children}
      <Footer  company={company}/>
    </div>
  );
};

export default Layouts;
