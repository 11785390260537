import React from "react";
import ReactWOW from 'react-wow'
const RequestStartLab = (props) => {   
    return (
        <ReactWOW animation='fadeInDown'>
        <section id="pricing-1" className="wide-60 pricing-section division">
            <div className="container">
                {/* SECTION TITLE */}
                <div className="row">
                    <div className="col-lg-10 offset-lg-1 section-title">
                        {/* Title 	*/}
                        <h3 className="h3-md steelblue-color">
                           Laborator Analize Medicale
                        </h3>
                        {/* Text */}
                        <p>
                            Dispunem de un laborator de analize performant, dotat cu aparatură de ultimă generație și acreditat RENAR.
                        </p>
                    </div>
                </div>
                { (props.company.CONFC_HomeHarvest == 1) || (props.company.CONFC_HomeHarvest == null) &&
                <div className="row pricing-row">
                    {/* PRICING TABLE #1 */}
                    <div className="col-lg-4">
                        <div className="pricing-table icon-xl">
                            {/* Icon */}
                            <span className="flaticon-104-blood-sample blue-color" />
                            {/* Title */}
                            <h5 className="h5-lg">Rezulate analize</h5>                           
                            {/* Pricing Plan Features  */}
                            <ul className="features">
                                <li>Pentru a afla rezultatele de analize realizate in cadrul laboratorului</li>
                                <li>pe baza CNP-ul si a codului unic</li>
                            </ul>
                            {/* Pricing Table Button  */}
                            <a
                                href={window.lotus+"/OnlineAccessLab"} 
                                className="btn btn-blue"
                            >
                               Rezultate analize
                            </a>
                        </div>
                    </div>
                    {/* END PRICING TABLE #1 */}
                    {/* PRICING TABLE #2 */}
                    <div className="col-lg-4">
                        {
                        <div className="pricing-table icon-xl">
                            {/* Icon */}
                            <span className="flaticon-008-ambulance-6 blue-color" />
                            {/* Title */}
                            <h5 className="h5-lg">Recoltare probe la domiciliu</h5>                            
                            <ul className="features">
                                <li>Recoltam probe la domiciliul pacientului</li>
                                <li>Completati datele de contact, adresa si data dorita</li>                              
                            </ul>
                            {/* Pricing Table Button  */}
                            <a
                                href={window.lotus+"/RequestHarvestLab"} 
                                className="btn btn-blue"
                            >
                               Recoltare domiciliu 
                            </a>
                        </div>
                        }
                    </div>
                    {/* END PRICING TABLE #1 */}
                    {/* PRICING TABLE #3 */} 
                    <div className="col-lg-4">
                        <div className="pricing-table icon-xl">
                            {/* Icon */}
                            <span className="flaticon-072-hospital-5 blue-color" />
                            {/* Title */}
                            <h5 className="h5-lg">Investigatii laborator</h5>                           
                            {/* Pricing Plan Features  */}
                            <ul className="features">
                                <li>Lista investigatii laborator</li>
                                <li>Plata si CNAS</li>
                            </ul>
                            {/* Pricing Table Button  */}
                            <a
                                href={window.lotus+"/ServiceListLab"} 
                                className="btn btn-blue"
                            >
                                Lista investigatii
                            </a>
                        </div>
                    </div>
                    {/* END PRICING TABLE #3 */}
                </div>
                }   
                { props.company.CONFC_HomeHarvest == 0 &&
                <div className="row pricing-row">
                    {/* PRICING TABLE #1 */}
                    <div className="col-lg-6">
                        <div className="pricing-table icon-xl">
                            {/* Icon */}
                            <span className="flaticon-104-blood-sample blue-color" />
                            {/* Title */}
                            <h5 className="h5-lg">Rezulate analize</h5>                           
                            {/* Pricing Plan Features  */}
                            <ul className="features">
                                <li>Pentru a afla rezultatele de analize realizate in cadrul laboratorului</li>
                                <li>pe baza CNP-ul si a codului unic</li>
                            </ul>
                            {/* Pricing Table Button  */}
                            <a
                                href={window.lotus+"/OnlineAccessLab"} 
                                className="btn btn-blue"
                            >
                               Rezultate analize
                            </a>
                        </div>
                    </div>
                    {/* END PRICING TABLE #1 */}                   
                    {/* PRICING TABLE #3 */} 
                    <div className="col-lg-6">
                        <div className="pricing-table icon-xl">
                            {/* Icon */}
                            <span className="flaticon-072-hospital-5 blue-color" />
                            {/* Title */}
                            <h5 className="h5-lg">Investigatii laborator</h5>                           
                            {/* Pricing Plan Features  */}
                            <ul className="features">
                                <li>Lista investigatii laborator</li>
                                <li>Plata si CNAS</li>
                            </ul>
                            {/* Pricing Table Button  */}
                            <a
                                href={window.lotus+"/ServiceListLab"} 
                                className="btn btn-blue"
                            >
                                Lista investigatii
                            </a>
                        </div>
                    </div>
                    {/* END PRICING TABLE #3 */}
                </div>
                }     
            </div>
            {/* End container */}
        </section>
        </ReactWOW>
    );
};

export default RequestStartLab;
