import React from 'react';
import { useState } from "react";
import Header from "@cloudscape-design/components/header";
import Container from "@cloudscape-design/components/container";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Input from "@cloudscape-design/components/input";
import Button from "@cloudscape-design/components/button";
import TopNavigation from "./TopNavigation"
import AppLayout from "@cloudscape-design/components/app-layout";
import Badge from "@cloudscape-design/components/badge";
import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";
import TextContent from "@cloudscape-design/components/text-content";
import Grid from "@cloudscape-design/components/grid";
import ColumnLayout from "@cloudscape-design/components/column-layout";
import SideNavigation from './Sidenavigation';
import TagManager from 'react-gtm-module'
import Link from "@cloudscape-design/components/link";
import 'photoswipe/dist/photoswipe.css'

export default function Code(props) {
    const [value, setValue] = useState("");
    const [menu, setMenu] = useState(false);

    const [activeHref, setActiveHref] = React.useState(
        "#/page1"
    );


    let content = {
        "ro": <SpaceBetween size="m">        
            <Container>
                <TextContent>
                <iframe
                        src={"https://maps.google.com/maps?q="+encodeURIComponent("Romania,Alba Iulia , Str. Bulevardul Revoluției Nr.49")+"&t=&z=13&ie=UTF8&iwloc=&output=embed"}
                        height={450}
                        width="100%"
                        style={{ border: 0 }}
                        allowFullScreen
                        loading="lazy"
                    />
                </TextContent>
                <TextContent>
                    <h1>Contact</h1>
                    <hr></hr>
                    <p>ADRESA : Alba Iulia , Str. Bulevardul   Revoluției Nr.49</p>
                    <p>Email: techxgsm@gmail.com , techxservicegsm@gmail.com</p>
                    <p>Tel. : 0729126916</p>
                    <p>© {new Date().getFullYear()} <span>TechXGsm</span>. All Rights Reserved - V:1.2</p>
                </TextContent>
            </Container>
        </SpaceBetween>        
    }


    return (
        <div>
            <TopNavigation language={props.language} setLanguage={props.setLanguage}
            />
            <AppLayout
                breadcrumbs={<BreadcrumbGroup
                    items={[
                        { text: "TechXGSM", href: window.lotus },
                        {
                            text: "Acasa",
                            href: "/"
                        }
                    ]}
                    ariaLabel="Breadcrumbs"
                />}
                contentHeader={
                    <div>
                        <Grid
                            gridDefinition={[{ colspan: 8 }, { colspan: 6 }]}
                        >
                            <div><Header
                                variant="h1"
                                description={"Telefonul tău are nevoie de o reparație? Suntem aici pentru tine cu servicii profesionale şi de calitate."}
                            >
                                TechXGSM
                            </Header>
                            </div>
                            <div></div>
                        </Grid>
                    </div>}
                navigation={<SideNavigation language={props.language} setLanguage={props.setLanguage}></SideNavigation>}
                navigationOpen={menu}
                onNavigationChange={(val) => { setMenu(val.detail.open); }}
                toolsHide="false"
                contentType="form"
                content={content[props.language]
                }>

            </AppLayout>
        </div>
    );
}