import React from 'react';
import { useState } from "react";
import TopNavigation from "@cloudscape-design/components/top-navigation";
import { propTypes } from 'react-bootstrap/esm/Image';

export default function Code(props) {
    const [value, setValue] = useState("");
    const [activeHref, setActiveHref] = React.useState(
        window.location.pathname
    );

    console.log(props);

    return (
        <TopNavigation
        identity={{
            href: window.lotus,
            title: "TechXGSM",
           
        }}
        utilities={[            
            {
                type: "button",
                text: "Servicii",
                href:window.lotus + "/services",
                external: false,
              },   
            {
                type: "button",
                text: "Contact",
                href:window.lotus + "/contact",
                external: false,
              },                                   
          ]}
        i18nStrings={{
            searchIconAriaLabel: "Search",
            searchDismissIconAriaLabel: "Close search",
            overflowMenuTriggerText: "More",
            overflowMenuTitleText: "All",
            overflowMenuBackIconAriaLabel: "Back",
            overflowMenuDismissIconAriaLabel: "Close menu"
        }}
    />
    );
}