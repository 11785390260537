import { CHANGE_SELECTION,USER_PROFILE,LOGIN_USER,LOGIN_USER_ERR,LOGOUT_USER,LOGIN_SENDING } from '../constants/actionTypes';
const assign = Object.assign || require('object-assign');

const initialState = { 
  Token:"",
  loggedIn: false,
  sending:false,
  errorMessage: '',
  profile:[],
  Selected:"",
  Code:"",
  Level:"",
  User:""
};

export default function loginReducer(state = initialState, action) {
  
  switch (action.type) {
    case LOGIN_SENDING:
      return assign({}, state, {        
        sending:action.sending
      });
    case LOGIN_USER:
      
    console.log(action);
      return assign({}, state, {
        Token: action.SmileToken,
        Code:action.Code,
        Level:action.Level,
        loggedIn:true,
        User:action.ID
      });
      case LOGIN_USER_ERR:
        alert(action.errorMessage);
      return assign({}, state, {
        errorMessage: action.errorMessage
      });
      case LOGOUT_USER:
      return assign({}, state, {
         loggedIn:false,
         Token:"",
         profile:[],
         Selected:"",
         Code:"",
          Level:"",
         errorMessage:"",
         User:""
      });
      case USER_PROFILE:
      return assign({}, state, {
         profile:action.profile,         
         Selected:action.selectednew,
         Level:action.Level,
         User:action.ID,
         loggedIn:true,
         Token: action.SmileToken,
         Code:action.Code    
      });
      case CHANGE_SELECTION:
      return assign({}, state, {
         Selected:action.selectednew
      });
    default:
      return state;
  }
}




