import React from 'react';
import Appointment from './Online/Appointment';
import Services from './layout/Service/AllServicesTabs2'
import Layouts from './layout/Layouts'
import Contacts from './layout/Contact/Contacts'
import Hero from "./layout/Hero/Hero";
import { Router, Route } from "react-router-dom";
import history from "./history";
const createRoutes = (props) => {
  console.log(props);
  
  let specialites = props.company.categories.map((item) => {    
    let count=0;
    return (     
      <Route path={"/"+item.Name.replace(" ","")} sitemapIndex={true} priority={1} changefreq={"daily"} exact render={(match) => (
        <Layouts  >
          
        </Layouts>                          
      )} />
    );
  });


  specialites.push(
    <Route sitemapIndex={true} priority={1} changefreq={"daily"} exact path="/" render={(match) => (
      <Layouts  company={props.company} >               
       <Hero  company={props.company}   />     
       <Services company={props.company}></Services>                   
     </Layouts>
  )} />
  );

  specialites.push(
    <Route sitemapIndex={true} priority={0.8} path="/Appointment" exact render={(match) => (
      <Layouts company={props.company}  >
      <Appointment  company={props.company}/>      
      </Layouts>                          
   )} />
  );

  specialites.push(
    <Route sitemapIndex={true} priority={0.9} path="/Contact" exact render={(match) => (
      <Layouts company={props.company}  >
      <Contacts  company={props.company}/>      
      </Layouts>                          
   )} />
  );

  return(
  <Router history={history}>    
          {specialites}
  </Router>);
};

export default createRoutes;