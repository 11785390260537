import React from 'react';
import { useState,useEffect } from "react";
import { BrowserRouter as Router,Switch, Route, Redirect } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Med from './layout/LotusCode/Med';
import Lab from './layout/LotusCode/Lab';
import Code from './layout/LotusCode/Code';
import RX24 from './layout/LotusCode/Rx24';
import AWS from './layout/LotusCode/AWS';
import Contact from './layout/LotusCode/Contact';
import Factory from './layout/LotusCode/Factory';
import TagManager from 'react-gtm-module';
import Sitemap from "./Sitemap";
import { applyTheme } from '@cloudscape-design/components/theming';

export default function AppLotus(props) {
  
  let region="";  
  let initiallanguage="en";
  if( window.location.origin.toString().includes("lotuscode.us"))
  {
     region="us";
     global.window.localStorage["lang"]="en";
  }
  else
  {
    region="eu"
    if(global.window.localStorage["lang"]!=null)
      initiallanguage=global.window.localStorage["lang"];
    else
      initiallanguage="ro";
  }
  
  const [language, setLanguage] = useState(initiallanguage);


  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-59297LX"
    }           
    TagManager.initialize(tagManagerArgs);

    const theme = {
      tokens: {
        // Values are applied globally, except for visual contexts
        //fontFamilyBase: "'Helvetica Neue', Roboto, Arial, sans-serif",
        colorBackgroundLayoutMain: {
          // Specify value for light and dark mode
          light: 'blue-300',
          dark: 'blue'
        },
        // Shorter syntax to apply the same value for both light and dark mode
        colorTextAccent: '#0073bb',
      },
      contexts: {
        // Values for visual contexts. Unless specified, default values will be applied
        'top-navigation': {
          tokens: {
            colorTextAccent: '#44b9d6',
          },
        },
        header: {
          tokens: {
            colorBackgroundLayoutMain: {
              // Specify value for light and dark mode
              light: '#f2f8fd',
              dark: '#f2f8fd'
            },
            colorTextHeadingDefault: {
              // Specify value for light and dark mode
              light: 'black',
              dark: 'black'
            },
          }
        }
      },
    };

    applyTheme({"theme":theme});

  }, []);
  
  let localhost = window.location.origin.toString();
  localhost=localhost.replace("site","www");
  window.lotus = localhost;

    return (
      <HelmetProvider>
        { region=="eu" &&
        <Helmet>
        <title>Lotus Code</title>
        <meta name="description" content="Software, Software laborator analize medicale, Software Clinici medicale, Consultanta AWS, Amazon Web Servicies" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="www.lotuscode.eu" />
        <meta property="og:title" content="Lotus Code"/>
        <meta property="og:description" content="Software development, Cloud,AWS,Amazon Web Servicies"/>
        <link rel="canonical" href="www.lotuscode.eu" />           
        </Helmet>
        }
        { region=="us" &&
        <Helmet>
        <title>Lotus Code</title>
        <meta name="description" content="Software, Medical analysis laboratory software, Medical clinic software, AWS Amazon Web Services consulting" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="www.lotuscode.us" />
        <meta property="og:title" content="Lotus Code"/>
        <meta property="og:description" content="Software development, Cloud, AWS, Amazon Web Servicies"/>
        <link rel="canonical" href="www.lotuscode.us" />           
        </Helmet>
        }
     <div>                   
        <div>
          <Router>
          <Switch>        
                   
            <Route path="/sitemap">
                  <Sitemap company={props.company}></Sitemap>
            </Route>


            <Route exact  path="/lotusmed" render={(match) => (
                <Med language={language} setLanguage={setLanguage} region={region}></Med>
            )} />

            <Route  exact  path="/lotuslab" render={(match) => (
                <Lab language={language} setLanguage={setLanguage} region={region}></Lab>
            )} />

            <Route  exact  path="/lotusrx24" render={(match) => (
                <RX24 language={language} setLanguage={setLanguage} region={region}></RX24>
            )} />

            <Route  exact  path="/lotusfactory" render={(match) => (
                <Factory language={language} setLanguage={setLanguage} region={region}></Factory>
            )} />

            <Route  exact  path="/aws" render={(match) => (
                <AWS language={language} setLanguage={setLanguage} region={region}></AWS>
            )} />

          <Route  exact  path="/contact" render={(match) => (
                <Contact language={language} setLanguage={setLanguage} region={region}></Contact>
            )} />

            <Route exact  path="/" render={(match) => (
                <Code language={language} setLanguage={setLanguage} region={region}></Code>
            )} />


          </Switch>
          </Router>
        </div>
      </div>
      </HelmetProvider>
    );
}


