import React from 'react';
import { useState } from "react";
import Header from "@cloudscape-design/components/header";
import Container from "@cloudscape-design/components/container";
import SpaceBetween from "@cloudscape-design/components/space-between";
import AppLayout from "@cloudscape-design/components/app-layout";
import SideNavigation from "./Sidenavigation";
import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";
import TextContent from "@cloudscape-design/components/text-content";
import Grid from "@cloudscape-design/components/grid";
import ColumnLayout from "@cloudscape-design/components/column-layout";
import TopNavigation from "./TopNavigation"
import Button from "@cloudscape-design/components/button";

export default function AWS(props) {
    const [value, setValue] = useState("");

    let content = {
        "en":<SpaceBetween size="m">
        <Container>
            <TextContent>
                <h1>Compute</h1>
                <hr></hr>
                <p>We heavily use  AWS ECS and AWS Lambda.</p>
                <p>We develop our own DevOps application which is in change of deployment, from git to production</p>
                <p>We also use EC2 for different tasks.</p>
                <p>We use AWS Batch to process for long time tasks.</p>
                <p>We use AWS ECS with EC2 and also with Fargate.</p>              
                <br></br>                
            </TextContent>
        </Container>
        <Container>
            <TextContent>
                <h1>Containers</h1>
                <hr></hr>
                <p>We love containers. We deploy containers using AWS ECS using our own developed DevOps tools. We rely on the AWS ECR to store our images.</p>
                <br></br>                
            </TextContent>
        </Container>
        <Container>
            <TextContent>
                <h1>Database</h1>
                <hr></hr>
                <p>We use a lot of Aurora databases, both with MySql and Postgres.</p>
                <p>We also use AWS ElastiCache Redis and Dynamodb for fast caching solutions.</p>
                <br></br>                
            </TextContent>
        </Container>
        <Container>
            <TextContent>
                <h1>Developer Tools</h1>
                <hr></hr>
                <p>We use almost all developer tools in AWS. We store our code in CodeCommit, as our git repository.We developed a custom application that can deploy directly from CodeCommit to AWS ECS, AWS Lambda, AWS Batch. This application uses AWS CodeBuild and AWS EventBrigde.</p>
                <p>We also use AWS CodeArtifact to store our npm artifacts.</p>
                <p>AWS Cloud9 with our python and node.js code.</p>
                <p>AWS X-Ray to instrument our application.</p>
                <p> We also deployed Prometheus in the ECS Cluster with Grafana to monitor our .Net Core backend applications.</p>
                <br></br>                
            </TextContent>
        </Container>,
        <Container>
            <TextContent>
                <h1>Networking</h1>
                <hr></hr>
                <p>VPC, CloudFront, Route 53, API Gateway</p>
                <br></br>                
            </TextContent>
        </Container>
        <Container>
            <TextContent>
                <h1>Business Applications</h1>
                <hr></hr>
                <p>AWS Connect - we can deploy call center applications using a custom CCP.</p>
                <p>Amazon Pinpoint - to send SMS </p>
                <p>Amazon Simple Email Service - to send emails</p>
                <br></br>                
            </TextContent>
        </Container>
        <Container>
            <TextContent>
                <h1>Application Integration</h1>
                <hr></hr>
                <p>We use AWS EventBrigde to respond to different events in AWS</p>
                <p>We use SQS and SNS to decouple our applications. </p>
                <br></br>                
            </TextContent>
        </Container>
        <Container>
            <TextContent>
                <h1>Machine Learning</h1>
                <hr></hr>
                <p>We use AWS Lex with AWS Connect to deploy smart bots into call center applications</p>
                <p>We use Amazon Polly with AWS Connect.</p>
                <p>Amazon Rekognition and Amazon Textract to process and read images information.</p>
                <p>Amazon Comprehend to process row chat data.</p>
                <br></br>                
            </TextContent>
        </Container>
        <Container>
            <TextContent>
                <h1>Analytics</h1>
                <hr></hr>
                <p>We use AWS Kinesis to process data from various sources including AWS Connect</p>
                <p>We use QuickSight to visualize and display analytics.</p>              
                <br></br>                
            </TextContent>
        </Container>
        <Container>
            <TextContent>
                <h1>Internet of Things</h1>
                <hr></hr>
                <p>IoT Core - to process data coming into cloud from medical analysys hardware</p>
                <br></br>                
            </TextContent>
        </Container>
            
    </SpaceBetween>,
    "ro":
    <SpaceBetween size="m">
    </SpaceBetween>
    }


    return (
        <div>
        <TopNavigation language={props.language} setLanguage={props.setLanguage} region={props.region}/>
        <AppLayout
            breadcrumbs={<BreadcrumbGroup
                items={[
                    { text: "Lotus Code", href: window.lotus },
                    {
                        text: "Brief Description of Our Infrastructure On AWS",
                        href: "/aws"
                    }
                ]}
                ariaLabel="Breadcrumbs"
            />}
            contentHeader={
                <Grid
                    gridDefinition={[{ colspan: 8 }, { colspan: 6 }]}
                >
                    <div><Header
                        variant="h1"
                        description={props.setLanguage=="ro"? "A Brief Description of Our Infrastructure On AWS":"A Brief Description of Our Infrastructure On AWS"}                        
                    >
                        AWS Cloud Infrastructure
                    </Header>
                    </div>
                    <div></div>
                </Grid>}
            navigation={<SideNavigation language={props.language}  setLanguage={props.setLanguage}></SideNavigation>}
            toolsHide="false"
            content={content["en"]}>
        </AppLayout>
        </div>
    );
}