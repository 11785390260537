import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Appointment from './Online/Appointment';
import ServicesMed from './layout/Service/AllServicesTabs2'
import ServicesList from './layout/Service/ServicesList'
import Evaluation from './layout/Evaluation/Evaluation'

import GDPR from './layout/Gdpr/Gdpr';
import Layouts from './layout/Layouts'

import Contacts from './layout/Contact/Contacts'
import RequestHarvestLab from './layout/LabRequest/RequestHarvestLab'
import RequestStartLab from './layout/LabRequest/RequestStartLab'
import OnlineAccessLab from './layout/LabRequest/OnlineAccessLab'
import ServiceListLab from './layout/LabRequest/ServiceListLab'
import HowTo from './layout/LabRequest/HowTo'

import StomaImplant from './layout/Stoma/Implant'
import StomaAesthetics from './layout/Stoma/Aesthetics'
import StomaRadiology from './layout/Stoma/Radiology'
import StomaOrthodontics from './layout/Stoma/Orthodontics'
import StomaPediatricdentistry from './layout/Stoma/Pediatricdentistry'
import StomaConsultations from './layout/Stoma/Consultations'

import DoctorDetail from './layout/Doctor/DoctorDetails'


import Hero from "./layout/Hero/Hero";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Sitemap from "./Sitemap";



class AppMed extends React.Component {

  render() {
    let specialites = this.props.company.categories.map((item) => {
      let count = 0;
      return (
        <Route path={"/" + item.Name.replace(" ", "")} exact render={(match) => (
          <Layouts company={this.props.company} config={this.props.config} >
            <ServicesMed filter={item.Name} company={this.props.company}></ServicesMed>
          </Layouts>
        )} />
      );
    });


    let localhost = window.location.origin.toString();
    window.lotus = localhost;

    let title = this.props.company.CONFC_Name + " " + this.props.company.CONFC_City;
    let script=`(function(w, d, x, id){
      s=d.createElement('script');
      s.src='https://d1ddlw7p7j6hta.cloudfront.net/amazon-connect-chat-interface-client.js';
      s.async=1;
      s.id=id;
      d.getElementsByTagName('head')[0].appendChild(s);
      w[x] =  w[x] || function() { (w[x].ac = w[x].ac || []).push(arguments) };
    })(window, document, 'amazon_connect', '`+ this.props.company.CONFC_Connect+`');
    amazon_connect('styles', { openChat: { color: 'white', backgroundColor: '#123456'}, closeChat: { color: 'white', backgroundColor: '#123456'} });
    amazon_connect('snippetId', '`+ this.props.company.CONFC_SnippetId+`');
    amazon_connect('customerDisplayName', function(callback) {
      callback('`+this.props.company.code+`');
    });
    amazon_connect('supportedMessagingContentTypes', [ 'text/plain', 'text/markdown' ]);`;

   
    return (
      <HelmetProvider>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={this.props.company.description} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={localhost} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={this.props.company.description} />
          <link rel="canonical" href={localhost} />
         
          {this.props.company.CONFC_Connect!=null &&
          <script type="text/javascript">
          {script}
          </script>
          }                
        </Helmet>
        <div>
          <div>
            <Router>
              <Switch>


                <Route path="/sitemap">
                  <Sitemap company={this.props.company}></Sitemap>
                </Route>

                <Route exact path="/" render={(match) => (
                  <Layouts company={this.props.company} config={this.props.config} >
                    <Hero company={this.props.company} />

                    {this.props.config.module.find(x => x.code == "RequestLab") &&
                      <RequestStartLab company={this.props.company}></RequestStartLab>
                    }



                    {this.props.config.module.find(x => x.code == "ServicesMed") &&
                      <React.Fragment>
                        <ServicesList company={this.props.company}></ServicesList>
                        <ServicesMed company={this.props.company}></ServicesMed>
                      </React.Fragment>
                    }

                  </Layouts>
                )} />




                {this.props.config.module.find(x => x.code == "ServicesMed") &&
                  <Route path="/DoctorDetail/:iddetail" exact render={(match) => (
                    <Layouts company={this.props.company} config={this.props.config}  >
                      <DoctorDetail value={match.match.params.iddetail} company={this.props.company}></DoctorDetail>
                    </Layouts>
                  )} />
                }


                {this.props.config.module.find(x => x.code == "RequestLab") &&
                  <Route path="/RequestHarvestLab" exact render={(match) => (
                    <Layouts company={this.props.company} config={this.props.config}  >
                      <RequestHarvestLab company={this.props.company}></RequestHarvestLab>
                    </Layouts>
                  )} />
                }

                {this.props.config.module.find(x => x.code == "RequestLab") &&
                  <Route path="/HowTo" exact render={(match) => (
                    <Layouts company={this.props.company} config={this.props.config}  >
                      <HowTo company={this.props.company}></HowTo>
                    </Layouts>
                  )} />
                }


                {this.props.config.module.find(x => x.code == "RequestLab") &&
                  <Route path="/OnlineAccessLab" exact render={(match) => (
                    <Layouts company={this.props.company} config={this.props.config}  >
                      <OnlineAccessLab company={this.props.company}></OnlineAccessLab>
                    </Layouts>
                  )} />
                }

                {this.props.config.module.find(x => x.code == "RequestLab") &&
                  <Route path="/ServiceListLab" exact render={(match) => (
                    <Layouts company={this.props.company} config={this.props.config} >
                      <ServiceListLab company={this.props.company}></ServiceListLab>
                    </Layouts>
                  )} />
                }


                <Route path="/Implant" exact render={(match) => (
                  <Layouts company={this.props.company} config={this.props.config}  >
                    <StomaImplant company={this.props.company} />
                  </Layouts>
                )} />


                <Route path="/Aesthetics" exact render={(match) => (
                  <Layouts company={this.props.company} config={this.props.config}  >
                    <StomaAesthetics company={this.props.company} />
                  </Layouts>
                )} />

                <Route path="/Radiology" exact render={(match) => (
                  <Layouts company={this.props.company} config={this.props.config} >
                    <StomaRadiology company={this.props.company} />
                  </Layouts>
                )} />
                <Route path="/Orthodontics" exact render={(match) => (
                  <Layouts company={this.props.company} config={this.props.config}  >
                    <StomaOrthodontics company={this.props.company} />
                  </Layouts>
                )} />
                <Route path="/Pediatricdentistry" exact render={(match) => (
                  <Layouts company={this.props.company} config={this.props.config}  >
                    <StomaPediatricdentistry company={this.props.company} />
                  </Layouts>
                )} />
                <Route path="/Consultations" exact render={(match) => (
                  <Layouts company={this.props.company} config={this.props.config}  >
                    <StomaConsultations company={this.props.company} />
                  </Layouts>
                )} />




                <Route path="/Policy" exact render={(match) => (
                  <Layouts company={this.props.company} config={this.props.config}  >
                    <GDPR company={this.props.company} />
                  </Layouts>
                )} />


                <Route path="/Evaluation" exact render={(match) => (
                  <Layouts company={this.props.company} config={this.props.config}  >
                    <Evaluation company={this.props.company} />
                  </Layouts>
                )} />

                {this.props.company.CONFC_OnlineAppointment==1 &&
                <Route path="/Appointment" exact render={(match) => (
                  <Layouts company={this.props.company} config={this.props.config}  >
                    <Appointment company={this.props.company} />
                  </Layouts>
                )} />
                }


                <Route path="/Contact" exact render={(match) => (
                  <Layouts company={this.props.company} config={this.props.config}  >
                    <Contacts company={this.props.company} />
                  </Layouts>
                )} />


               <span>{specialites}</span>
               
              </Switch>
            </Router>
          </div>
        </div>
      </HelmetProvider>
    );

  }

}

export default AppMed;
