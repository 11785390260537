import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper.scss';
import SwiperCore, { EffectFade, Navigation, Pagination } from "swiper";
SwiperCore.use([Pagination, Navigation, EffectFade]);
import { Button } from "react-bootstrap";


const Services3 = (company) => {
  
  const service3Props = {
    slidesPerView: 2,
    spaceBetween: 15,
    loop: false,
    autoplay: true,
    speed: 800,
    pagination: {
      el: ".owl-dots",
      clickable: true,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      600: {
        slidesPerView: 1,
      },  
      768: {
        slidesPerView: 3,
      },
    
    },
  };

  let specialites = company.company.categories.map((item) => {    
    let count=0;
    return (     
      <SwiperSlide className="sbox-3 icon-sm" key={count++}>
      {/* Icon */}
      <div className="sbox-3-icon">
        <span className="flaticon-083-stethoscope" />
      </div>      
      <h5 className="h5-xs steelblue-color">{item.Name.toString().trim()}</h5>      
      <Button href={window.lotus+"/"+item.Name.toString().trim().replace(" ","")}
                                    variant="link"
                                  >
                                      Mai multe detalii
                                  </Button>
      </SwiperSlide>
    );
  });
    

  return (
    <section
      id="services-3"
      className="bg-lightgrey wide-100 services-section division"
    >
      <div className="container">
        {/* SECTION TITLE */}
        <div className="row">
          <div className="col-lg-10 offset-lg-1 section-title">
            {/* Title 	*/}
            <h3 className="h3-md steelblue-color">
              Specialitati medicale
            </h3>           
          </div>
        </div>
        {/* SERVICES CONTENT */}
        <div className="row">
          <div className="col-md-12">
            <Swiper                
               {...service3Props}
              className="owl-carousel owl-theme services-holder"             
            >              
              {specialites}
              <div className="owl-dots"></div>
            </Swiper>
          </div>
        </div>
        {/* END SERVICES CONTENT */}
      </div>
      {/* End container */}
    </section>
  );
};

export default Services3;
