import React from "react";
// The import below should be updated to match your Router component
// import Routes from "../createRoutes";
import DynamicSitemap from "react-dynamic-sitemap";
import createRoutes from "./SitemapRoutes";

export default function Sitemap(props) {
    return (
        <DynamicSitemap routes={createRoutes} prettify={true} {...props}/>
    );
}