import React from 'react';
import { useState } from "react";
import TopNavigation from "@cloudscape-design/components/top-navigation";
import { propTypes } from 'react-bootstrap/esm/Image';

export default function Code(props) {
    const [value, setValue] = useState("");
    const [activeHref, setActiveHref] = React.useState(
        window.location.pathname
    );

    let buttons=[            
        {
            type: "button",
            text: "Contact",
            href:window.lotus + "/contact",
            external: false,
            variant:"primary-button"
        }    
      ];

    if(props.region=="eu")
    {
        buttons.push( {
            type: "button",
            text: "Romana",
            variant:props.language=="ro" ? "primary-button":"link",
            onClick:(() => {global.window.localStorage["lang"]="ro";props.setLanguage("ro");})
        }        
       );
       buttons.push({
        type: "button",
        text: "English",
        variant:props.language=="en" ? "primary-button":"link",
        onClick:(() => {global.window.localStorage["lang"]="en";props.setLanguage("en");})
        });        
    };


    return (
        <TopNavigation
        identity={{
            href: window.lotus,
            title: "Lotus Code",
            logo: {
                src:"images/logo.png",
                alt: "Service"
            }
        }}
        utilities={
            buttons
        }
        i18nStrings={{
            searchIconAriaLabel: "Search",
            searchDismissIconAriaLabel: "Close search",
            overflowMenuTriggerText: "More",
            overflowMenuTitleText: "All",
            overflowMenuBackIconAriaLabel: "Back",
            overflowMenuDismissIconAriaLabel: "Close menu"
        }}
    />
    );
}